.tooltipCon {
  max-height: 170px;
  // overflow-y: scroll !important;

  @media (max-width: #{$md}) {
    height: 100px;
    overflow-y: scroll !important;
  }

  @media (max-width: 813px) and (orientation: landscape) {
    height: 100px;
    overflow-y: scroll !important;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 125px;
    overflow-y: scroll !important;
  }
}

.tooltipLink {
  &:hover {
    .d-block {
      // background-color: pink !important;
      text-decoration: underline !important;
      color: $white !important;
      background-color: $gray !important;
    }
  }
}
.tooltipLabel {
  position: absolute;
  top: -18px;
  font-size: 10px;
  text-transform: uppercase;
  padding: 3px 5px;
  letter-spacing: 2px;
  background: $trout;
  color: $white;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-align: center;
}

.data-blur {
  text-align: center;
  color: transparent;
  text-shadow: 0 0 7px #000;
  position: relative !important;
  .dummyLockToolTip {
    padding-right: 0px !important;
    margin-right: -1rem;
  }
}

.gen-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  .tooltip-text {
    visibility: hidden;
    width: 13rem;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 9999;
    &.top {
      top: -2rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.8rem;
      border-radius: 1rem;
    }
    &.bottom {
      bottom: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.8rem;
      border-radius: 1rem;
    }
  }
  &:hover .tooltip-text {
    visibility: visible;
  }
}
