.paginationLinkGroup {
  align-items: baseline;

  .active {
    color: $chineseBlack;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: $orange;
      height: 4px;
      border-radius: 2px;
    }
  }

  @include sm {
    justify-content: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
  }
}

.paginationLink {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: $fadedGrey;
  margin-right: 1rem;
  cursor: pointer;
  position: relative;
  border: 0;
  background-color: $white;
  padding: 0;

  &:last-of-type {
    margin-right: 0;
  }
}

.paginationLinkGroup {
  align-items: baseline;

  .active {
    color: $chineseBlack;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: $orange;
      height: 4px;
      border-radius: 2px;
    }
  }

  .paginationLink {
    background: transparent;
  }

  .active {
    border: none !important;
  }
  @include sm {
    justify-content: center;
    margin-bottom: 15px;
  }
}
.pagination {
  // width: 100%;
  padding: 10px;
  display: flex;
  min-width: 300px;
  // flex-direction: row-reverse;
  .btn-primary,
  .btn-orange {
    @include sm {
      width: 227px;
      height: 56px;
      margin-bottom: 14px;
      font-family: var(--font-inter);
      font-size: 16px;
      font-weight: 600;
    }
  }

  .box {
    .btn-primary {
      padding: 5px 20px;
      margin: 0px 10px;
      display: inline-flex;
      align-items: center;

      img {
        height: 20px;
        margin-left: 5px;
      }
    }
    span {
      padding-left: 2px;
      padding-right: 2px;
      font-size: 18px;
    }
    .back {
      margin-left: 10px;
      margin-right: 15px;
    }
    .btns {
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
      &:hover {
        color: $bronze;
      }
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .disabled {
      color: $dustyGrey;
      &:hover {
        color: $dustyGrey !important;
      }
    }

    .pagination-status {
      display: inline-block;
    }
  }
  .page-link {
    display: flex;
    height: 100%;
    align-items: center;
  }
}
