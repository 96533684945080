.GameCardWrapper {
  background-color: $white;
  box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);
  min-height: 150px;
  margin: 1rem;
  border-radius: 16px;
  overflow: hidden;
  padding: 1rem;
  display: grid;
  align-items: center;
  cursor: pointer;

  .GameCard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;

    h1 {
      font-family: var(--font-poppins);
      font-size: 20px;
      @media (max-width: 540px) {
        font-size: 18px;
      }
    }

    div {
      z-index: 2;
    }

    .score {
      font-family: var(--font-poppins);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 50%;
      text-align: center;
      font-size: 85px;
      font-weight: 600;
      display: grid;
      align-items: center;
      transition: all 0.35s ease-in-out;
    }

    .GameCard__team--name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 45vw;
      color: $arsenic;
    }

    .GameCard__team--logo {
      transition: all 0.35s ease-in-out;
      //margin: 5px auto;
      &::before {
        content: "N/A";
        display: block;
        height: 40px;
        width: 40px;
        margin-bottom: 10px;
        background: rgba(128, 128, 128, 0.177);
        border-radius: 10px;
        @media (max-width: 540px) {
          width: 40px;
          height: 40px;
        }
        margin-left: 10%;
        margin-right: 10%;
      }

      &::after {
        content: "";
        display: block;
        font-size: 12px;
      }
    }

    .GameCard__team--logo {
      max-width: 60px;
    }

    .GameCard__team--logo.logo-left {
      transform: perspective(500px) translateX(0px) rotateX(0deg) rotateY(-27deg);
    }

    .GameCard__team--logo.logo-right {
      transform: perspective(500px) translateX(0px) rotateX(0deg) rotateY(27deg);
    }

    &:hover {
      .GameCard__team--logo {
        transform: perspective(500px) translateX(0px) rotateX(0deg) rotateY(0deg);
      }
    }

    .GameCard__team--chart {
      position: absolute;
      top: 35%;
      bottom: 10%;
      margin: auto;
      display: grid;
      align-items: center;
      // font-size: 7vh; //300%; //70px;
      font-size: 70px;
      font-weight: 600;
      text-shadow: 2px 2px $bronze;
    }

    .GameCard__team--chart2 {
      position: absolute;
      top: 20%;
      bottom: 10%;
      left: 30%;
      margin: auto;
      display: grid;
      align-items: center;
      font-size: 120%; //3.5vmin; //70px;
      font-weight: 600;
      padding-top: 10%;
      padding-bottom: 30%;
      text-shadow: 1px 1px $bronze;
    }

    .GameCard__team--chart3 {
      position: absolute;
      top: 5%;
      bottom: 10%;
      right: 0%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: auto;
      display: grid;
      align-items: center;
      font-size: 125%; // 3vmin; // 3vmin; //70px;
      font-weight: 600;
      padding-top: 10%;
      padding-bottom: 30%;
      text-shadow: 1px 1px $bronze;
    }

    .GameCard__team--chart.team-left {
      left: 50%;
      right: 10%;
      //padding-bottom: 20%;
    }

    .GameCard__team--chart.team-right {
      right: 50%;
      //left: 0%;
      //padding-bottom: 20%;
      white-space: nowrap;
    }

    .lock {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: calc(30% - 10px);
      height: calc(100% - 10px);
      display: grid;
      align-items: center;
      text-align: center;

      background-size: 50px 50px;
      background-repeat: no-repeat;
      background-position: center center;
      img {
        max-width: 50px;
      }
    }
  }

  .GameCard__prediction {
    height: 30px;
    background-color: rgba(211, 211, 211, 0.2);
    border-radius: 6px;
    display: grid;
    grid-template-columns: 1fr;
    //padding: 3px 5px;
    padding: 2px 5px;
    margin-top: 5%;
    //padding-top: 10%;

    @media (max-width: 540px) {
      height: 25px;
    }

    .GameCard__prediction--bar {
      background-color: rgb(20, 186, 20);
      border-radius: 6px;
      position: relative;
      overflow: hidden;

      img {
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0%;
        bottom: 0;
        margin: auto;
        padding: 3px;
        padding-left: 15px;
        background-color: $white;
        clip-path: circle(81.5% at 100% 51%);
      }
      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        width: 50px;
        height: 100%;
        font-weight: bold;
      }
    }
  }

  .location {
    position: absolute;
    top: 0;
    width: 50%;
    margin: auto;
    left: 0;
    bottom: 0;
    text-align: center;
    right: 0;
    transform: translateY(-10px);
    font-weight: bold;
    font-size: 12px;
  }

  .GameCard__prediction--bar {
    position: relative;
    span {
      position: absolute;
      height: 100%;
      text-align: center;
      color: $white;
      width: 0;
      transition: all 5s ease-in-out;
    }
  }

  .GameCard__prediction--bar.team-left {
    span {
      right: 0;
      background: red;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }
  .GameCard__prediction--bar.team-right {
    span {
      background: rgb(102, 214, 102);
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      left: 0;
    }
  }

  @media (max-width: 540px) {
    margin-left: 0;
    margin-right: 0;
    min-height: 170px;
  }
}

.gamecard-enter {
  opacity: 0;
  transform: scale(0.9);
}
.gamecard-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.gamecard-exit {
  opacity: 1;
}
.gamecard-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.gameCard:hover {
  .hover-score-team {
    display: flex;
  }
  @include md {
    .hover-score-team {
      display: hidden;
    }
  }
}
