// #Placeholder Color
@mixin font-awesome {
  font-family: $fontOsm;
}
// box shadow mixin
@mixin box-shadow($boxShadow) {
  box-shadow: $boxShadow;
}
// border radius mixin
@mixin border-radius($radius) {
  border-radius: $radius;
}
// link transition mixin
@mixin transition($time) {
  transition: $time;
}
// input appearance mixin
@mixin appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// extra small devices
@mixin xs {
  @media (max-width: #{$xs}) {
    @content;
  }
}
//  small devices
@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: #{$md}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}
// Extra large devices
@mixin xl {
  @media (max-width: #{$xl}) {
    @content;
  }
}

//Width: 1440px
@mixin xxl2 {
  @media (max-width: #{$xxl2}) {
    @content;
  }
}

@mixin md-portrait {
  @media all and (min-device-width: 768px) and (max-device-height: 1366px) and (orientation: portrait) {
    @content;
  }
}

@mixin md-landscape {
  @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation: landscape) {
    @content;
  }
}

@mixin container-fluid-lg {
  @media (min-width: 1401px) {
    padding-right: 3.5vw;
    padding-left: 3.5vw;
    margin: auto;

    .auth-buttons-container {
      padding-right: 0;
      padding-left: 0;
      margin: 0;
    }
  }
}

@mixin header-primary-base {
  background: transparent !important;
  padding: 10px 15px;
  padding-top: 34px;
  padding-bottom: 30px;
  h2 {
    color: #0f131b;
    margin: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
  }
}

@mixin section-base {
  padding-right: 15px;
  padding-left: 15px;
  @include md {
    padding-left: 1rem;
    padding-bottom: 1rem;
    margin: 0;
  }
}

@mixin card_base {
  min-width: 300px;
  transition: all 0.25s ease-in-out;

  &:hover {
    box-shadow: -2px 3px 33px 4px $bronze;
    -webkit-box-shadow: -2px 3px 33px 4px $bronze;
    -moz-box-shadow: -2px 3px 33px 4px $bronze;
    border-color: $bronze;
  }
}
.table-4n-bg {
  &:nth-child(4n-2),
  &:nth-child(4n-3) {
    background-color: white !important;
    td {
      background-color: white !important;
    }
  }
  &:nth-child(4n-1),
  &:nth-child(4n) {
    background-color: #dddddd !important;
    td {
      background-color: #dddddd !important;
    }
  }
}
.table-responsive.responsive-to-card table tbody tr.table-4n-bg {
  &:nth-child(odd) {
    @include sm {
      margin-bottom: 0px !important;
      border-bottom: 0px !important;
    }
  }
  &:nth-child(even) {
    @include sm {
      margin-top: 0px !important;
      border-top: 0px !important;
    }
  }
}
