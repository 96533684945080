.form-control {
  font-size: 16px;
}

.form-control-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  min-height: 50px;
  overflow: hidden;

  // width: $slider-width;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    height: 15px;
    background-color: transparent;
    border: 2px solid $gray;
    border-radius: 10px;
  }

  &::-webkit-slider-thumb {
    transform: translateY(-2.5px) scale(1.5);
    border: 2px solid $gray;
  }

  &::-moz-range-track {
    height: 30px;
  }
}

.form-input {
  position: relative;

  input {
    border: 2px solid;
    padding-left: 2.5rem;
    &:focus {
      border-width: 2px;
    }
  }

  .form-icon {
    position: absolute;
    font-size: 1.5rem;

    margin: auto;
    display: inline-block;
    align-items: center;
    height: 100%;
    bottom: 0;
    top: 0;
    max-width: 50px;
    &.form-icon__left {
      left: 10px;
    }
    &.form-icon__right {
      right: 10px;
    }
  }
}

.from-checkbox {
  .form-icon {
    font-size: 1.5rem;
    margin-left: 10px;
  }
}
