// Media Query Variables
$xs: 374px;
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1440px;
$xxl2: 1440px;

.has-gigantic-font-size {
  font-size: 144px;
  line-height: 130px;
}

.has-huge-font-size {
  font-size: 96px;
  line-height: 80px;
}

.has-extra-large-font-size {
  font-size: 40px;
  line-height: 36px;
}

.has-large-font-size {
  font-size: 24px;
}

.has-normal-font-size {
  font-size: 20px;
}

// extra small devices
@mixin xs {
  @media (max-width: #{$xs}) {
    @content;
  }
}
//  small devices
@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: #{$md}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}
// Extra large devices
@mixin xl {
  @media (max-width: #{$xl}) {
    @content;
  }
}

//Width: 1440px
@mixin xxl2 {
  @media (max-width: #{$xxl2}) {
    @content;
  }
}

@mixin md-portrait {
  @media all and (min-device-width: 768px) and (max-device-height: 1366px) and (orientation: portrait) {
    @content;
  }
}

@mixin md-landscape {
  @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation: landscape) {
    @content;
  }
}

@mixin container-fluid-lg {
  @media (min-width: 1401px) {
    padding-right: 3.5vw;
    padding-left: 3.5vw;
    margin: auto;
    max-width: 1600px;

    .auth-buttons-container {
      padding-right: 0;
      padding-left: 0;
      margin: 0;
    }
  }
}

.article {
  .article_title {
    // background-color: $orange;
    // color: $white;
    font-family: var(--font-inter);
    font-size: 40px;
    font-weight: 700;
    text-align: start !important;

    & h2 {
      font-size: 40px;
    }
  }
  .article_content {
    font-family: var(--font-inter);
    font-size: 16px;
    max-width: 750px;
    margin: auto;
    // padding: 1rem;
    iframe {
      width: 100%;
    }

    & strong {
      font-size: 18px;
      font-weight: 800;
      color: #000;
    }
  }

  .img-article-1 {
    height: 410px;
    background-position: center center;
    // background-image: url(../images/article1.png);
    background-size: cover;
    border-radius: 24px;
    // padding: 40px 30px;

    // @include sm {
    //   width: 327px;
    //   height: 250px;
    // }
  }

  .previousOrNextPostWrapper {
    font-size: 16px;
    font-weight: 600;
    color: #f26b0b;
    text-decoration: none;
  }

  .previousOrNextPost {
    font-size: 14px;
    color: #0f131b;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-top: 14px;
  }

  .previousOrNextPost img {
    height: 75px;
    width: 100px;
  }

  .previousOrNextPost span {
    width: 187px;
  }
  .osw-24 {
    font-family: var(--font-oswald);
    font-size: 24px;
  }

  .article-tag {
    font-family: var(--font-inter);
    font-weight: 500;
    font-size: 14px;
    padding: 12px 24px;
    border-radius: 100px;
    border: none;
    // margin: 20px 8px 0px 8px;
    display: inline-flex;

    &:first-child {
      margin-left: 0;
      margin-right: 0;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

iframe {
  height: 450px;
  width: 700px;
  display: block;
  position: relative;
}

.wp-block-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}
