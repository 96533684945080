.rdp {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: auto;
  overflow-x: hidden;
  flex-shrink: inherit;

  @include sm {
    width: auto;
  }
}

.rdp-head {
  border: 1px solid $red;
  width: fit-content;
  flex-shrink: inherit;
  padding: 0;
  margin: 0;
}
.rdp-head_row {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  border: 10px solid $green;
  flex-shrink: inherit;
  padding: 0;
  margin: 0;
}

.rdp-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 0;
  margin: 0;

  @include sm {
    width: auto;
  }
}

.rdp-cell {
  padding-top: 0 !important;
}

.rdp-head_cell {
  // border: 10px solid blue;
  padding: 0;
  margin: 0;
}

.rdp-tbody {
  align-items: center;
  width: 100%;
  border: 1px solid $red;
  margin: 0;
  padding: 0;
  display: flex;

  flex-wrap: wrap;

  @include sm {
    justify-content: center;
  }
}

.rdp-day {
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 6px;
  border: 1px solid $lightGrey;
  border-radius: 16px;
  background-color: $white;
  font-family: var(--font-inter);
  font-weight: bold;

  @include sm {
    padding: 0;
  }

  &:hover {
    border: 1px solid $orange;
    color: $orange;
  }
}
.rdp-day_selected {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 6px;
  border: 1px solid $orange;
  border-radius: 16px;
  background-color: rgba(242, 107, 11, 0.1);
  font-family: var(--font-inter);
  color: $orange;
}
.rdp-head_cell span {
  &:first-of-type {
    display: none;
  }

  @include sm {
    &:first-of-type {
      display: initial;
    }

    &:last-of-type {
      display: none;
    }
  }
}

.rdp-vhidden {
  @include sm {
    display: none;
  }
}

.rdp-day_outside {
  border: 0;
  background-color: $athensGrey;
  color: $fadedGrey;
}
.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: rgba(242, 107, 11, 0.1);
}
// .rdp-button:focus-visible:not([disabled]) {
//   background-color:  rgba(242, 107, 11, 0.1);
// }
.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: $darkBlue;
  --rdp-background-color: $red;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: $interdimensionalBlue;
  --rdp-background-color-dark: $deepViolet;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}
