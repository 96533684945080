.BracketWrapper {
  .championship {
    h1 {
      font-family: var(--font-poppins);
      font-weight: bolder;
      letter-spacing: 2px;
      font-size: 2.5rem;
      // font-style: italic;
      text-shadow: 3px 3px $bronze;
    }

    img {
      width: 200px;

      @media (max-width: 990px) {
        width: 100px;
      }
    }
  }
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: calc(35vw - 20px);
    height: calc(35vw - 20px);
    background-image: url("../assets/images/favicon.png");
    background-position: center center;
    background-size: cover;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-repeat: no-repeat;
    opacity: 0.1;
  }
  #tournament {
    display: flex;
    flex-direction: row;
    .round {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 200px;
      list-style: none;
      padding: 0;

      @media (max-width: 990px) {
        width: 100px;
      }
    }
    .round .spacer {
      flex-grow: 1;
    }
    .round .spacer:first-child,
    .round .spacer:last-child {
      flex-grow: 0.5;
    }

    .round .game-spacer {
      flex-grow: 1;
    }

    li.game {
      padding-left: 20px;
      height: 40px;
      display: grid;
      grid-template-columns: 1fr 40px;
      font-size: 13px;
      font-weight: 600;
      img {
        max-width: 30px;
        margin: auto;
      }
    }

    li.game span {
      float: right;
      margin-right: 5px;
    }

    li.game-top {
      border-bottom: 2px solid$darkGreyX11;
    }

    li.game-spacer {
      // border-right: 1px solid$darkGreyX11;
      min-height: 40px;
    }

    li.game-bottom {
      border-top: 2px solid$darkGreyX11;
    }

    .left-border {
      border-left: 2px solid gray;
    }

    .right-border {
      border-right: 2px solid gray;
    }

    .round-1 {
      .game-top,
      .game-bottom {
        width: 175px;

        @media (max-width: 990px) {
          width: 125px;
        }

        display: grid;
        align-items: center;
      }
    }

    li {
      .rankIndex {
        font-family: var(--font-poppins);
        font-weight: 400;
        position: absolute;
      }
    }

    li.left {
      padding-left: 10px;
      .rankIndex {
        left: -25px;
      }
    }

    li.right {
      padding-right: 10px;
      text-align: right;
      .rankIndex {
        right: -25px;
      }
      .teamName {
        text-align: left;
      }
    }

    .round-1 {
      li.winner {
        background-color: rgba(0, 128, 0, 0.05);
        transition: background-color 0.25s ease-in-out;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 128, 0, 0.2);
        }
      }
      li.game-bottom {
        background-color: rgba(255, 0, 0, 0.05);
        transition: background-color 0.25s ease-in-out;
        cursor: pointer;
        &:hover {
          background-color: rgba(255, 0, 0, 0.2);
        }
      }
    }

    .round-2,
    .round-3,
    .round-4 {
      .game {
        grid-template-columns: 1fr;
      }
      .info {
        display: none;
      }
      .teamName {
        display: none;
      }
      text-align: center;
    }

    .round-2 {
      .spacer {
        flex-grow: 0.5;
        &:first-child {
          flex-grow: 0.26;
        }

        &:last-child {
          flex-grow: 0.26;
        }
      }
      .game-spacer {
        flex-grow: 1.25;
      }
    }

    .round-3 {
      .spacer {
        flex-grow: 0.85;
        &:first-child {
          flex-grow: 0.35;
        }

        &:last-child {
          flex-grow: 0.35;
        }
      }
      .game-spacer {
        flex-grow: 1.25;
      }
    }

    .round {
      overflow: visible;
      .info {
        display: block;
        position: absolute;
        width: 250px;
        height: 150px !important;

        height: 100%;
        text-align: center;
        opacity: 0;
        // clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
        color: $white;
        z-index: -1;
        text-transform: uppercase;
        font-family: var(--font-poppins);
        background-color: $white;
      }

      .info_hover {
        display: block;
        position: absolute;
      }

      .game.left.game-top,
      .game-bottom {
        right: 0;

        &:hover {
          .info {
            right: -275px;
            z-index: 2;

            box-shadow: -2px 3px 33px 4px $bronze;
            -webkit-box-shadow: -2px 3px 33px 4px $bronze;
            -moz-box-shadow: -2px 3px 33px 4px $bronze;

            -webkit-animation: tilt-in-left-1 0.65s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

            animation: tilt-in-left-1 0.65s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
          }
        }
      }

      .game.right.game-top,
      .game.right.game-bottom {
        left: 0;

        &:hover {
          .info {
            opacity: 1;

            left: -275px;
            z-index: 2;

            box-shadow: -2px 3px 33px 4px $bronze;
            -webkit-box-shadow: -2px 3px 33px 4px $bronze;
            -moz-box-shadow: -2px 3px 33px 4px $bronze;

            -webkit-animation: swing-in-right-fwd 0.65s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

            animation: swing-in-right-fwd 0.65s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
          }
        }
      }

      // .game-top,
      // .game-bottom {
      //   &:hover {
      //     .info {
      //       right: -60px;
      //       opacity: 1;
      //     }
      //   }
      // }
    }
  }

  .finalFourColumnWrapper {
    @media (min-width: 991px) {
      overflow: visible;
      position: unset;
    }
  }

  .finalFour {
    @media (max-width: 990px) {
      position: relative;
      width: 100%;
    }

    @media (min-width: 991px) {
      position: absolute;
      top: -100px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 300px;
      height: 300px;
    }

    h2 {
      text-transform: uppercase;
      font-weight: bold;
      font-family: var(--font-poppins);
      letter-spacing: 3px;
    }
    .finalFour--location {
      font-size: 12px;
      font-style: italic;
    }

    .FinalFourBlock {
      margin: 10px 0px;
      p {
        height: 40px;
        margin: 0;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 35px;
        text-align: left;
        padding: 5px 10px;
        font-weight: bold;

        img {
          max-width: 30px;
          float: right;
        }
        &:first-child {
          border-bottom: 1px solid lightgray;
        }
      }
      p.top {
        cursor: pointer;
        background-color: rgba(0, 128, 0, 0.1);
        transition: background-color 0.25s ease-in-out;
        &:hover {
          background-color: rgba(0, 128, 0, 0.3);
        }
      }
      p.bottom {
        cursor: pointer;
        background-color: rgba(255, 0, 0, 0.05);
        transition: background-color 0.25s ease-in-out;
        &:hover {
          background-color: rgba(177, 67, 67, 0.3);
        }
      }
    }
  }
}

.NewVFSummaryBox {
  background-color: $white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 50px;

  .toolTip {
    position: absolute;
    bottom: -65px;
    left: 185px;
    text-align: center;
    width: 230px;
    border: 1px solid $fadedGrey;
    border-radius: 5px;
    color: $black;
    padding: 7px 14px;
    font-size: 14px;

    .closeBtn {
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 0 6px;
      border: 1px solid $fadedGrey;
      background-color: $athensGrey;
      border-radius: 50%;
    }

    &:hover {
      display: initial;
    }

    &::before {
      display: flex;
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      right: 95px;
      top: -9px;
      transform: rotate(45deg);
      background-color: $athensGrey;
      border-top: 1px solid $fadedGrey;
      border-left: 1px solid $fadedGrey;
    }
  }
}

.danKatz-container {
  max-width: 510px;

  position: relative;
  margin-bottom: 300px;

  @include sm {
    margin-left: -90px;
    margin-bottom: 200px;
    padding-left: 0;
  }
  .ball-white {
    position: absolute;
    z-index: 1;
    width: 254px;
    top: 0px;
    right: 50px;
  }

  .profile-image {
    position: absolute;
    z-index: 2;
    width: 200px;
    top: 85px;

    @include sm {
      width: 100px;
      top: 0;
    }
  }
  .description-container {
    background: $white;
    box-shadow: 0px 4px 61px -18px rgb(15 19 27 / 23%);
    border-radius: 10px;
    padding: 30px;
    z-index: 3;
    position: relative;
    width: 410px;
    top: 230px;
    left: 110px;

    @include sm {
      width: 280px;
      top: 80px;
      left: 53px;
      margin-bottom: 60px;
    }
  }

  .fio {
    display: flex;
    justify-content: space-between;
  }

  .image-que {
    height: 32px;
  }

  .description {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $chineseBlack;
  }

  .fio-container {
    p {
      font-family: var(--font-oswald);
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 140%;
      letter-spacing: 0.02em;
      color: $chineseBlack;
    }

    p {
      &:last-child {
        font-family: var(--font-inter);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: $fadedGrey;
      }
    }
  }
  // description-container
}

/* Model Sandbox Page Ends */
#MatchupTool {
  @media (max-width: 1000px) {
    padding: 10px;
  }
  background-color: $white;
  padding: 0 60px;
  .MatchupTool {
    padding-top: 50px;
  }

  .titles-container {
    width: 100%;
    p {
      color: $fadedGrey;
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      margin-bottom: 40px;
    }

    h2 {
      color: $chineseBlack;
      font-weight: 700;
      font-size: 30px;
      width: 100%;
    }
  }

  .select-teams-container {
    @media (max-width: 1000px) {
      width: 100%;
      padding: 5px;
    }
    text-align: center;
    background-color: $athensGrey;
    padding: 50px;
    border-radius: 24px;
  }

  .select-container {
    @media (max-width: 1000px) {
      margin-top: 15px;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    margin-top: 40px;

    p {
      margin-bottom: 8px;
    }
  }

  .select-teams-button {
    padding: 16px 46px;
    border-radius: 10px;
    background-color: $chineseBlack;
    margin-top: 40px;
    color: $white;
    cursor: pointer;

    a {
      color: $white;
    }
  }

  .accordion-container {
    margin-top: 47px;
    padding-bottom: 50px;

    .select-container {
      padding: 0;
    }
  }
  .matchup-slider.hide-duplicates .slick-cloned {
    display: none;
  }
  .matchup-slider-mobile .item-slider-list {
    display: flex;
    justify-content: center;
  }
  .slider {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    // overflow-x: hidden!important;
    // margin-bottom: 50px;
  }
}

/* Matchup Output Page Starts */

.Output {
  .title {
    color: $white;
    font-weight: 700;
    font-size: 16px;
    background-color: $chineseBlack;
    padding: 12px 25px;
    margin-bottom: 0;
    border-radius: 12px;
  }

  .table-header-wrap {
    flex-wrap: wrap;
  }

  // .RankCell {
  //   @media (max-width: 1000px) {
  //     padding: 10px;
  //     font-size: 13px;
  //     width: 200px;
  //   }
  //   min-width: auto !important;
  // }

  .headerWrapper {
    @media (max-width: 1000px) {
      height: 250px;
      margin-bottom: 5px;
      padding-top: 5px;
    }
    height: 250px;
    margin-top: 0;
    margin-bottom: 40px;
    padding-top: 30px;
    color: $white;

    background: url("../../src/assets/images/matchup-output-header.png");

    .teamLogoWrapper {
      width: 70px;
      height: 70px;
      background-color: $white;
      border-radius: 50%;
      display: flex;

      & svg {
        margin: auto;
      }
    }

    .matchDate p {
      text-align: center;
      font-size: 12px;
      color: $white;
      margin-bottom: 10px;
    }

    .teamInfo {
      p,
      h3,
      span {
        color: $white;
      }

      p {
        font-weight: 500;
        margin-bottom: 6px;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .teamLogoMiniWrapper {
    @media (max-width: 1000px) {
      margin-left: 20px;
      margin-right: 20px;
    }
    border-radius: 50%;
    display: flex;
    height: 24px;
    width: 24px;
    border: 1px solid $lightGrey;

    & svg {
      margin: auto;
    }
  }

  .table {
    border-collapse: separate;
    border-spacing: 0;

    a {
      text-decoration: none;
      color: inherit;
      font-weight: 600;
      font-size: 14px;

      svg {
        margin-right: 6px;
        font-size: 20px;
      }

      span {
        vertical-align: middle;
      }
    }

    tr {
      overflow: hidden;
      border-radius: 12px;

      &:nth-child(odd) {
        td {
          background-color: $white;
        }
      }

      &:nth-child(even) {
        td {
          background-color: rgba(242, 244, 246, 0.5);
        }
      }

      td:first-child {
        border-left: 1px solid $lightGrey;
      }

      td:last-child {
        border-right: 1px solid $lightGrey;
      }

      &:last-child td:first-child {
        border-left: 1px solid $lightGrey;
        border-radius: 0 0 0 12px;
      }

      &:last-child td:last-child {
        border-right: 1px solid $lightGrey;
        border-radius: 0 0 12px 0;
      }

      &.shotTypeStatsRow {
        td.RankCell {
          b {
            margin-bottom: 10px;
          }
          span,
          small {
            text-align: center;
            // position: initial;
          }
        }
      }

      &.descriptionRow {
        td {
          text-align: center;
        }
      }
    }

    th {
      background: rgb(15, 19, 27);
      color: $white;
      font-weight: 700;
      font-size: 16px;
      border-top: 0;

      &:first-child {
        border-top-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
      }

      label {
        color: $white;
        font-weight: 400;
        font-size: 14px;
      }

      .custom-control-label {
        &::after {
          background-color: $chineseBlack;
          border: 1px solid $manatee;
          border-radius: 3px;
        }
      }
    }
    td {
      border-bottom: 1px solid $lightGrey;
      border-top: 0;

      &.RankCell {
        small {
          border: 1px solid $lightGrey;
          border-radius: 4px;
        }
        // span {
        //   left: 25px;
        // }
      }
    }
  }

  .shotTypeStats td {
    padding: 12px 6px;

    &.RankCell {
      b {
        cursor: pointer;
      }
      small {
        border: 1px solid $lightGrey;
        border-radius: 4px;
        // right: 25px;
      }
      // span {
      //   left: 25px;
      // }
    }

    &:nth-child(3) {
      vertical-align: middle;
    }
  }

  .descriptionRow {
    background-color: $wildSand !important;
    td {
      padding: 12px;
    }
  }

  .mainResult {
    @media (max-width: 1000px) {
      flex: 1;
      padding: 20px;
    }
    background-color: $white;
    box-shadow: 2px 4px 30px rgba(107, 108, 114, 0.12);
    border-radius: 12px;
    padding: 30px;

    .teamLogoWrapper {
      @media (max-width: 1000px) {
        width: 46px;
        height: 46px;
      }
      width: 54px;
      height: 54px;
      border: 1px solid $lightGrey;
      border-radius: 50%;
      display: flex;

      svg {
        margin: auto;
      }
    }

    .gameScore {
      @media (max-width: 1000px) {
        font-size: 36px;
        padding: 8px;
      }
      font-family: var(--font-oswald);
      font-size: 44px;
      font-weight: 700;
      color: $fadedGrey;
      display: flex;
      align-items: center;
    }

    p.teamName {
      font-weight: 700;
      font-size: 16px;
      color: $fadedGrey;
    }

    p.teamScoreStats {
      color: $chineseBlack;
      font-weight: 600;
      font-size: 14px;
    }
    a:hover {
      color: $orange !important;
    }
  }

  .gameScoreStats {
    .table tr td:first-child {
      border-left: 1px solid hsl(210, 20%, 98%) !important;
    }

    .table tr td:last-child {
      border-right: 1px solid$aliceBlue !important;
    }

    .table tr:last-child td {
      border-bottom: 1px solid$aliceBlue !important;
    }

    .table tr td {
      &:first-child,
      &:last-child {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .teamAchievements {
    background: $white;
    border-radius: 12px;
    box-shadow: 2px 4px 30px rgba(107, 108, 114, 0.12);

    ul {
      padding-left: 30px;
      padding-right: 30px;

      li {
        margin-bottom: 14px;
        font-family: var(--font-inter);
        font-size: 14px;
        color: $black;

        &::marker {
          color: $orange;
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .playtypePrediction {
    background-color: $white;
    border-radius: 12px;
    box-shadow: 2px 4px 30px rgba(107, 108, 114, 0.12);
    overflow: hidden;

    .title {
      color: $white;
      font-weight: 700;
      font-size: 16px;
      background-color: $chineseBlack;
      padding: 12px 25px;
      margin-bottom: 0;
    }

    ul {
      padding-left: 30px;
      padding-right: 30px;

      li {
        margin-bottom: 14px;
        font-family: var(--font-inter);
        font-size: 14px;
        color: $black;

        &::marker {
          color: $orange;
          width: 8px;
          height: 8px;
        }
      }
    }

    .showMore {
      color: $orange;
      background: transparent;
      border: 0;
      font-size: 14px;
      font-weight: 600;

      display: flex;
      align-items: center;
      margin: auto;
      margin-bottom: 32px;

      cursor: pointer;

      svg {
        margin-left: 10px;
      }
    }
  }

  .showMore-record-table {
    color: $orange;
    background: transparent;
    border: 0;
    font-size: 14px;
    font-weight: 600;

    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 32px;

    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }
}

/* Matchup Output Page Ends */

/* TrendFinder Page Start */

.pastGame {
  @media (max-width: 1000px) {
    text-align: center;
    padding: 5px;
  }
  background: $white;
  box-shadow: 2px 4px 30px rgba(107, 108, 114, 0.12);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;

  .tag {
    background: rgba(0, 51, 86, 0.15);
    border-radius: 20px;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    width: 105px;
    padding: 8px 16px;
  }

  .teamLogoMiniWrapper {
    border-radius: 50%;
    display: flex;
    height: 24px;
    width: 24px;
    border: 1px solid $lightGrey;

    & svg {
      margin: auto;
    }
  }

  p {
    @media (max-width: 1000px) {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 5px;
      text-align: center;
    }
    font-weight: 500;
    font-size: 16px;
    color: $fadedGrey;
    margin-bottom: 26px;
  }

  span {
    @media (max-width: 1000px) {
      font-size: 16px;
      margin-top: 1px;
      margin-bottom: 1px;
      text-align: center;
    }
    font-family: var(--font-oswald);
    font-weight: 700;
    font-size: 24px;
    color: $chineseBlack;
    margin-bottom: 16px;
  }
}

.upcomingGameWrapper {
  @extend .pastGame;

  background: none;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;

  .upcomingGame {
    @extend .pastGame;
    margin-bottom: 0;
    .tag {
      width: 164px;
      background: rgba(101, 185, 61, 0.2);
    }
  }
}

.clickable-div {
  cursor: pointer;
}

/* TrendFinder Page Ends */

@media only screen and (min-width: 991px) {
  /* --- One --- */
  .navbar .collapse ul {
    li {
      position: relative;
      &:hover > ul {
        display: block;
      }
    }
    /* --- Two --- */
    ul {
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 250px;
      display: none;
      background-color: dodgerblue !important;
      li {
        position: relative;
        &:hover > ul {
          display: block;
        }
      }
      /* --- Three --- */
      ul {
        position: absolute;
        top: 0;
        left: 100%;
        min-width: 250px;
        display: none;
        background-color: aqua !important;
        li {
          position: relative;
          &:hover ul {
            display: block;
          }
        }
        /* --- Four --- */
        ul {
          position: absolute;
          top: 0;
          left: -100%;
          min-width: 250px;
          display: none;
          z-index: 1;
          background-color: bisque !important;
          li {
            position: relative;
            &:hover ul {
              display: block;
            }
          }
          /* --- Five --- */
          ul {
            position: absolute;
            top: 0;
            left: -100%;
            min-width: 250px;
            display: none;
            z-index: 1;
            background-color: fuchsia !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .sticky-th-1 {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 188px; /* 0px if you don't have a navbar, but something is required */
    background: $white;
    z-index: 5;
  }

  .sticky-th-2 {
    position: -webkit-sticky !important;
    position: sticky !important;
    // top: 98px; /* 0px if you don't have a navbar, but something is required */
    background: $white;
    z-index: 5;
  }
  .sticky-th-100px {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 104px;
    z-index: 5;
  }
}

/* Profile settings*/
.ProfileSettings {
  background-color: $athensGrey;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;

  .edit-password {
    position: relative;

    img,
    a.icon {
      height: 18px;
      top: 15px;
      position: absolute;
      right: 10px;
    }
  }

  .container {
    max-width: 610px;
  }

  .userInfo {
    background: $white;
    border: 1px solid $lightGrey;
    border-radius: 12px;
    width: 100%;
    padding: 40px;

    .user-name {
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 140%;
      color: $chineseBlack;
    }

    label {
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: $chineseBlack;
    }
  }

  .titlePage {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: $chineseBlack;
    margin-bottom: 30px;
  }

  .upgrade-plan-block {
    display: flex;
    flex-direction: column;

    .plan-titles {
      margin-bottom: 24px;
      .title {
        font-family: var(--font-inter);
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        margin-bottom: 0px;
        color: $chineseBlack;
      }

      .type {
        font-family: var(--font-inter);
        font-style: normal;
        font-weight: 600;
        font-size: 0.8rem;
        line-height: 140%;
        color: $orange;
        background: $linen;
        border-radius: 15px;
        padding: 5px 18px;

        @include sm {
          height: fit-content;
          line-height: normal;
          // padding: 5px 10px;
        }
      }
    }

    div {
      display: flex;
      flex-direction: row;
    }
  }

  .buttons-act {
    padding: 16px 44px;

    @include sm {
      padding: 7px 5px;
    }
  }

  .Cancel-Plan {
    background-color: $white;
    color: $chineseBlack;
    border-color: $chineseBlack;
  }

  .time-billing {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $chineseBlack;
    margin-bottom: 40px;
  }

  .userInfo input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $lightGrey;
    border-radius: 0px;
  }

  .cancell-plan-buttons {
    display: flex;
    flex-direction: column !important;
    width: 100%;

    .full-width {
      width: 100% !important;
    }
  }
  .payment-details-plan {
    display: flex;
    justify-content: space-evenly;
  }
  .payment-details-plan .full-width {
    width: 100% !important;
  }

  .upgrade-plan-block .full-width {
    width: 100% !important;
  }
  .list {
    padding: 0;
    list-style: none;
    margin: 0;
    margin-bottom: 40px;
    li {
      position: relative;
      padding-left: 15px;
      font-size: 16px;
      font-family: $fontFamilyPop;
      font-weight: 400;
      color: $codGrey;
      margin-bottom: 10px;
      &:before {
        position: absolute;
        top: 8px;
        left: 0;
        content: "";
        width: 6px;
        height: 6px;
        @include border-radius(50%);
        background-color: $orange;
      }
      &:last-child {
        margin-bottom: 0;
      }
      ol {
        display: block;
        margin-top: 10px;
        list-style: none;
        padding: 0;
        li {
          color: $darkLiver;
        }
      }
    }
  }
  .cancelled-description {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $chineseBlack;
    margin-bottom: 40px;
  }

  .cancelled-date-blue {
    color: $trueBlue;
  }
  .upgrade-plan {
    max-width: 100%;

    .titlePage {
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 150%;
      margin-bottom: 10px;
      color: $chineseBlack;
      text-align: center;
    }

    .description-upgrade {
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: $fadedGrey;
      text-align: center;
    }

    .value-finder-buttons-group {
      color: $white;
      background-color: transparent !important;
      border-radius: 10px !important;
      margin-left: 0px !important;
      margin-right: 0px !important;
      opacity: 1;

      &.active {
        background: $orange !important;
      }
    }
    .switch-container {
      text-align: left !important;
    }

    .plan-item {
      background: $white;
      border: 1px solid $lightGrey;
      border-radius: 12px;
      padding: 30px;
      margin-top: 40px;
      width: 400px;

      @include sm {
        margin-bottom: 32px;
        width: 100%;
      }
    }
    .plan-item-title {
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      letter-spacing: -0.01em;
      color: $black;
    }
    .plan-item-description {
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.01em;
      color: $fadedGrey;
    }
    .plan-item-price {
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.01em;
      color: $chineseBlack;
      display: flex;
      align-items: center;

      span {
        font-family: var(--font-oswald);
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: $chineseBlack;
        margin-right: 10px;
      }
    }

    .buttons-plan-container {
      display: flex;
      flex-direction: column;
    }

    .plans-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1.5rem;
      width: 1280px;
      margin: auto;

      @include sm {
        flex-direction: column;
        width: auto;
        gap: 1rem;
      }
    }

    .new-sub-plans-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 1280px;
      margin: auto;

      @include sm {
        flex-direction: column;
        width: auto;
        gap: 1rem;
      }

      .danKatz-container {
        max-width: 510px;
        padding-left: 70px;
        position: relative;

        @include sm {
          padding-left: 0;
        }
        .ball-white {
          position: absolute;
          z-index: 1;
          width: 254px;
          top: 0px;
          right: 50px;
        }

        .profile-image {
          position: absolute;
          z-index: 2;
          width: 200px;
          top: 85px;

          @include sm {
            width: 100px;
            top: 0;
          }
        }
        .description-container {
          background: $white;
          box-shadow: 0px 4px 61px -18px rgb(15 19 27 / 23%);
          border-radius: 10px;
          padding: 30px;
          z-index: 3;
          position: relative;
          width: 410px;
          top: 230px;
          left: 110px;

          @include sm {
            width: 280px;
            top: 80px;
            left: 53px;
            margin-bottom: 60px;
          }
        }

        .fio {
          display: flex;
          justify-content: space-between;
        }

        .image-que {
          height: 32px;
        }

        .description {
          font-family: var(--font-inter);
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: $chineseBlack;
        }

        .fio-container {
          p {
            font-family: var(--font-oswald);
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: $chineseBlack;
          }

          p {
            &:last-child {
              font-family: var(--font-inter);
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              color: $fadedGrey;
            }
          }
        }
        // description-container
      }
    }

    .point-image {
      height: 20px;
      margin-right: 16px;
    }
    .plan-item-options {
      p {
        display: flex;
        align-items: center;
        font-family: var(--font-inter);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: $chineseBlack;
      }
    }

    .plan-item-premium {
      position: relative;
      margin-left: -150px;
      @media (max-width: 600px) {
        margin-left: 0px;
      }

      &:before {
        background-repeat: no-repeat;
        position: absolute;
        top: -10px;
        right: -10px;
        content: "";
        height: 80px;
        width: 80px;
        background-color: $athensGrey;
        border-radius: 100%;
        background-size: 64px;
        background-position: center;
        background-image: url(../../src/assets/images/ball-bask.png);
      }

      .buttons-act {
        color: $white;
        background-color: $orange;
        border-color: $orange;
      }

      .trial {
        color: $orange;
        background-color: $white;
        border-color: $orange;
      }

      .Cancel-Plan {
        color: $orange;
        background-color: $white;
      }

      .notAvailable {
        position: absolute;
        top: 40%;
        left: 0;
        text-align: center;

        h3 {
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 16px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .weekly-premium {
      @extend .plan-item-premium;
      filter: blur(2.5px);
      margin: -30px;
      &:after {
        width: 405px;
        height: 588px;
        position: absolute;
        top: 0px;
        left: -4px;

        content: "";
        background: linear-gradient(180deg, rgba(248, 249, 251, 0) 0%, $ghostWhite 42.19%);
      }
    }
  }

  .back-button-history {
    display: flex;
    align-items: center;
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: $chineseBlack;
    cursor: pointer;

    img {
      height: 15px;
      margin-right: 15px;
    }
  }

  .checkout {
    max-width: 520px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;

    .titlePage {
      text-align: left !important;
    }

    .checkout-plan {
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: $chineseBlack;
    }
    .checkout-plan-description {
      background: $linen;
      border: 0.5px solid $orange;
      border-radius: 8px;
      padding: 4px 10px;
      width: fit-content;
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: $orange;
    }
    .checkout-price {
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: $chineseBlack;
      display: flex;
      align-items: center;

      span {
        font-family: var(--font-inter);
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: $black;
      }
    }
  }
  .checkout-container {
    background: $white;
    padding: 40px;
    border: 1px solid $lightGrey;
    border-radius: 12px;
  }

  .checkout-container {
    .title {
      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: $chineseBlack;
    }
  }

  .price-container {
    background: rgba(242, 244, 246, 0.54);
    border: 1px solid $lightGrey;
    border-radius: 8px;
    padding: 26px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .promocode {
    background: $white;
    border: 1px solid $lightGrey;
    backdrop-filter: blur(11px);
    border-radius: 8px;
    // width: 75%;
    input {
      width: 100%;
      height: 100%;
      padding-left: 20px;

      font-family: var(--font-inter);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: $fadedGrey;
    }
  }

  .promocode-container {
    display: flex;
    justify-content: space-between;

    .form-input {
      width: 75%;
      margin-bottom: 0px;

      @include sm {
        width: 65%;
      }
    }
  }

  .promocodes {
    padding-bottom: 40px;
    margin-bottom: 30px;
    border-bottom: 1px solid $lightGrey;
  }

  label {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $chineseBlack;
  }
  .apply-promo {
    width: 20%;

    @include sm {
      width: 30%;
    }
  }

  .promo-price-plan {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .promo-price-plan-title {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $fadedGrey;
  }
  .promo-price-plan-summary {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: $black;
  }

  .total-plan {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: $chineseBlack;
  }

  .buy-plan-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .paymentError {
    color: red;
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  .faq-container {
    width: 95vw;
    padding: 50px;
    margin: 50px auto auto;
    background: $white;
    align-items: center;
    border-radius: 10px;

    h2.heading {
      width: 70vw;
      margin: 0px auto;
      color: $orange;
    }

    div.content {
      width: 70vw;
      margin: 50px auto;
      padding: 30px;
      background: $linen;
      border-radius: 10px;
    }
    @media (max-width: 768px) {
      padding-left: 0px;
      padding-right: 0px;
      h2.heading {
        width: 85vw;
      }
      div.content {
        width: 85vw;
      }
    }
  }
}
/* Transfers Page Starts */

.Transfers {
  .header-primary {
    @include header-primary-base();
  }

  .container-fluid {
    @include container-fluid-lg;
  }

  .form-group {
    margin-bottom: 0;
  }
}
/* Transfers Page Ends */

/* Misc Stats Starts */

.MiscStats {
  background-color: $athensGrey;
  .form-group {
    margin-bottom: 0;
  }

  .rodateArrow {
    transform: rotate(180deg);
  }
  .container-fluid {
    @include container-fluid-lg();
  }

  .header-primary {
    //background-color: $black;
    @include header-primary-base();
    @include md() {
      padding: 1rem 0rem;
      h2 {
        text-align: center;
      }

      .form-group {
        margin: 0.5rem 1rem;
      }
    }
    button[type="submit"] {
      position: absolute;
      right: 0;
      top: 0;
      right: 15px;
      img {
        max-width: 30px;
      }
    }
    .search-results {
      width: 90%;
      margin: auto;
      left: 0;
      right: 0;
      top: 9rem;
      padding: 20px 30px;
      max-height: 304px;
      overflow-y: scroll;

      border: 1px;
      gap: 14px;
      isolation: isolate;
      background: $white;
      box-shadow: 2px 4px 30px rgba(107, 108, 114, 0.12);
      border-radius: 8px;

      &::-webkit-scrollbar-thumb {
        background: $fadedGrey;
        border-radius: 10px;
        height: 30px;
        width: 2px !important;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }
      .text-left-2 {
        font-family: var(--font-inter);
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 140%;
        background: transparent;
        text-align: left;
        color: $chineseBlack;
      }
      span {
        font-family: var(--font-inter);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        border-bottom: 0px;
        color: $chineseBlack;
        margin-bottom: 14px;
      }
    }
  }
  .header-secondary {
    @media (max-width: 1000px) {
      width: 100%;
      padding: 5px 5px 5px 15px;
    }
    padding: 15px;
    margin: 1rem 0;

    .col-xs-12:nth-child(1) {
      text-align: left;
      @include md {
        display: grid;
        text-align: center;
      }

      @include md-portrait {
        display: grid;
        gap: 1rem;

        h3 {
          text-align: center;
        }
        .btn {
          max-height: 50px;
        }
      }
    }

    .col-xs-12:nth-child(2) {
      text-align: right;
      .btn-group,
      .btn-export {
        margin-left: 1rem;
      }
      @include md {
        display: grid;
        gap: 0.5rem;
        margin-top: 0.5rem;
        .btn-group,
        .btn-export {
          margin-left: 0;
        }
      }

      @include md-portrait {
        display: grid;
        gap: 1rem;

        .btn {
          max-height: 50px;
        }
      }
    }

    .col-xs-12:nth-child(3) {
      text-align: right;
      .btn-group,
      .btn-export {
        margin-left: 1rem;
      }
      @include md {
        display: grid;
        gap: 0.5rem;
        margin-top: 0.5rem;
        .btn-group,
        .btn-export {
          margin-left: 0;
        }
      }

      @include md-portrait {
        display: grid;
        gap: 1rem;

        .btn {
          max-height: 50px;
        }
      }
    }
  }

  .page-content {
    @media (min-width: 728px) {
      margin: 20px;
    }
    @include md {
      padding: 0;
    }
  }

  section {
    padding-right: 15px;
    padding-left: 15px;
    @include md {
      padding-left: 1rem;
      padding-bottom: 1rem;
      margin: 0;
    }
  }

  .header-container {
    // margin-top: 54px;

    @include sm {
      padding-top: 40px;
      margin-top: 0;
    }
  }

  .search-input {
    border-radius: 8px;
    background: $white;
    border: 1px solid $orange;
  }

  .export-button {
    border: 1.6px solid $chineseBlack;
    border-radius: 10px;
    background-color: transparent;
    color: $chineseBlack;
    font-weight: 700;
    font-size: 16px;

    .export-button-icon {
      height: 20px;
      margin-left: 10px;
      margin-bottom: 2px;
    }
  }

  .value-finder-buttons-group {
    color: $white;
    background-color: transparent !important;
    border-radius: 10px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    opacity: 1;

    &.active {
      background: $orange !important;
    }
  }
  .switch-container {
    text-align: left !important;
  }

  table {
    tr {
      &:nth-child(odd) {
        background-color: $white;
      }
      &:nth-child(even) {
        background-color: $lightGrey;
      }
    }
  }

  .fixed-positionheader {
    position: relative !important;
    top: 0px;

    th {
      position: relative !important;
      top: 0px;
    }
  }
  .Picks.PlayerProps {
    .card-text {
      color: #0f131b;
    }
    .small,
    small {
      font-weight: 500;
    }
    table {
      thead th span {
        text-align: center;
      }
      tr {
        td {
          background-color: transparent;
          border: none;
        }
        // &:nth-child(even) {
        //   background-color: #f9f9f9;
        // }
        @extend .table-4n-bg;

        &:nth-child(odd) td {
          border-top: 1px solid #dee2e6;
        }
      }
    }
    .card-body {
      table {
        tr {
          th {
            text-align: center;
            // &:first-child {
            //   text-align: left;
            // }
          }
          td {
            text-align: center;
          }
        }
      }
    }
  }
}
.buttons-category-container {
  text-align: right !important;
  padding-right: 0px !important;
  .btn-group-justified {
    .btn-primary {
      background: $white !important;
      border-radius: 10px;
      color: $chineseBlack;
      opacity: 1;
      margin-left: 10px;
      margin-bottom: 10px;

      &.active {
        background-color: $orange !important;
        color: $white;
        border-width: 0px !important;
      }
    }
  }
}
/* Misc Stats Ends */

/* Stats Explained Starts */

.StatsExplained {
  min-height: 100vh;
  padding-top: 3rem;
  background-color: $white;
  border: none;

  @include md {
    padding-top: 1rem;
  }

  .container-fluid {
    @include container-fluid-lg();
  }

  section {
    @include section-base();
    margin: auto;

    .container {
      border-radius: 6px;
      box-shadow: none;

      h2 {
        color: $chineseBlack;
      }
    }
  }

  .stats-explained {
    padding-top: 0px;
    h2 {
      font-weight: 700;
      font-size: 44px;
      color: $chineseBlack;
    }

    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      color: $chineseBlack;
    }

    .Aboutside {
      background: $athensGrey !important;
      border-radius: 12px;
      margin-top: 50px;

      .container {
        background: transparent !important;
      }
    }

    .gray-background-container {
      background: $athensGrey;
      border-radius: 12px;
      padding: 60px;
    }

    .sq-points-tittle {
      font-weight: 700;
      font-size: 28px;
      color: $orange;
    }

    .text-template {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: $black;
    }

    .bold-text {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: $black;
    }

    .table-responsive {
      border: 1px solid $lightGrey;
      border-radius: 20px;
      border-top: none;
    }

    .table {
      thead {
        background: $black;
        tr th {
          &:first-child {
            border-right: 1px solid $lightGrey;
          }
        }
      }
      tr td {
        font-weight: 700;
        font-size: 14px;
        &:first-child {
          font-weight: 700;
          font-size: 16px;
          color: $chineseBlack;
          border-right: 1px solid $lightGrey;
        }
        &:last-child {
          font-weight: 400;
          font-size: 16px;
          color: $chineseBlack;
        }
      }

      th,
      td {
        padding-left: 50px !important;
      }
    }

    .misc-stats-explain-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 150%;
      color: $orange;
      margin-bottom: 12px;
      margin-top: 30px;
    }
  }

  .contact-info {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: $athensGrey;
    border-radius: 12px;
    flex-direction: column;
    background-size: 100%;
    background-image: url(../../src/assets/images/contact-backgrund.jpg);
    background-repeat: no-repeat;

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      color: $chineseBlack;
      margin-bottom: 30px;
    }

    a {
      padding: 16px 38px;
      top: 106px;
      background: $chineseBlack;
      border-radius: 12px;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;

      color: $white;
    }
  }
}
.have-a-question-section {
  background: url("../assets/images/stats-explained/basketball-horizontal-overlay.png") no-repeat center center/cover;
}
.stats-explained {
  * {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.4;
  }
  p,
  li {
    font-size: 1.2rem;
    line-height: 1.5;
    color: black;
    font-weight: normal;
  }
  ol li::marker {
    margin-left: -1rem;
  }
  .stats-card-holder {
    margin: auto;
    gap: 2rem;
    .image-holder {
      position: relative;
      min-width: 47%;
      .holder-content {
        position: absolute;
        bottom: 1.2rem;
        left: 0.4rem;
        color: white;
        border-radius: 0 0 0 1rem;
        padding: 0.5rem 1rem;
      }
      .title {
        font-size: 2rem;
        font-weight: 700;
        color: white;
      }
      .arrow-btn {
        height: fit-content;
        padding: 0.4rem 1.6rem;
        padding-bottom: 0.5rem;
        font-size: 1.4rem;
        color: white;
        border-radius: 24px;
      }
    }
  }
  .stats-explained-card-holder {
    max-width: 80rem;
    .image-holder {
      min-width: 28%;
    }
  }
  .contentful-blogs-section {
    color: white;
    h2 {
      color: white;
    }
    .btn {
      background: $orange;
    }
  }
  .sqppp-ratings-and-table {
    // background: #f4f5f9;
    // border-radius: 1rem;
    overflow: hidden;
    td,
    th {
      padding: 1rem;
      font-weight: 600;
      border: 2px solid #dddddd;
      font-size: large;
    }
    th {
      background: #efefef;
      font-weight: 700;
    }
  }
}
/* Stats Explained Ends */

/* My Team Page Starts */

.MyTeam {
  min-height: 100vh;
  padding-top: 3rem;
  @include md {
    padding-top: 1rem;
  }

  .MyTeam-image {
    max-width: 90%;
    padding: 2rem;

    margin-bottom: 1rem;
    border-radius: 6px;
    text-align: center;
    position: relative;
    overflow: hidden;

    img {
      z-index: 2;
      position: relative;
      max-width: 100px;
      transform: scale(1.5);
    }

    span {
      &::before {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        z-index: 1;
      }
    }
  }

  .container-fluid {
    @include container-fluid-lg();
  }

  section {
    @include section-base();
    margin: auto;

    .container {
      border-radius: 6px;
    }
  }
}

/* My Team Page Ends */

/* Lineup Vals Template Starts */

.LineupValsTemplate {
  .TableOverlay_content {
    top: 20%;
    h2 {
      font-family: var(--font-poppins);
      text-align: center;
      font-size: 3rem;

      @include md {
        font-size: 2rem;
      }
    }

    div {
      text-align: center;
    }
    position: absolute;

    width: 100%;
    z-index: 2;

    // @include md {
    //   bottom: 20%;
    // }

    svg {
      font-size: 5rem;
      text-align: center;

      @include md {
        font-size: 4rem;
      }
    }
  }
}
/* Lineup Vals Template Ends */

/* March Madness Coming Soon Starts */

.MarchMadness {
  .comingsoon {
    height: 500px;
    background-color: $white;
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    border-radius: 15px;

    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
    justify-content: center;

    h1 {
      font-size: 4rem;
      font-family: $fontFamilyPop;
      color: $orange;
    }
  }
}

/* March Madness Coming Soon Ends */

/*March Madness Template Starts*/

.MarchMadnessTemplate {
  .TableOverlay_content {
    text-align: center;
    bottom: 65% !important;
    h2 {
      font-family: $fontFamilyPop;
      text-align: center;
      font-size: 3rem;

      @include md {
        font-size: 2rem;
      }
    }
    .TableOverlay_content__lock {
      font-size: 5rem;
      text-align: center;
      margin: auto;
      display: flex;
    }

    ul {
      list-style: none;
      max-width: 500px;
      margin: auto;

      li {
        gap: 0.5rem;
        display: flex;
        margin-top: 1rem;

        i {
          display: inline-block;
        }
      }
    }
  }
}
/*March Madness Template Ends*/

/* Build a Bracket Starts */

.BuildABracket {
  .hero {
    background-color: $black;
    @media (min-width: #{$md}) {
      h4 {
        font-size: 42px;
        min-height: 95px;
        display: inline-flex;
        align-items: center;
        background-color: $black;
      }
    }

    @media (max-width: #{$sm}) {
      h4 {
        min-height: 35px;
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
/* Build a Bracket Ends */

/* Build A Bracket Template Starts */

.BuildABracketTemplate {
  .TableOverlay_content {
    text-align: center;
    h2 {
      font-family: $fontFamilyPop;
      text-align: center;
      font-size: 3rem;

      @include md {
        font-size: 2rem;
      }
    }
    .TableOverlay_content__lock {
      font-size: 5rem;
      text-align: center;
      margin: auto;
      display: flex;
    }
  }
}
/* Build A Bracket Template Ends */

/* HomePage Start */
.homepage-container {
  position: relative;
  min-height: 40vh;
  & * {
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background: url("../../public/static/media/price-block-bg.png") no-repeat center center/cover;
    opacity: 0.1;
    z-index: 1;
    filter: blur(1px);
    @include sm {
      width: 100%;
    }
  }
}
.homePage {
  padding: 0;
  padding-top: 4rem;

  @include xl {
    padding: 3rem 2rem;
  }

  @include sm {
    padding: 2rem 2rem;
  }

  h1 {
    font-family: var(--font-inter);
  }
  p {
    @include sm {
      font-size: 14px;
    }
  }

  .btn {
    // padding: 16px 20px;
    // max-width: 183px;
    min-width: 7rem;

    @include sm {
      margin: auto;
      // transform: translateY(-2rem);
    }
  }

  .MiscStats.best-bets {
    background-color: rgb(242 244 246 / 54%);
    border-radius: 10px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 4rem;
    .heading-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      gap: 1rem;
    }
    .outer {
      overflow-x: hidden !important;
    }
  }
}

.premium {
  background-color: $chineseBlack;
  margin-bottom: 24px;
  padding-top: 2rem;

  @include xl {
    margin-bottom: 0;
  }

  span {
    background: $linen;
    border-radius: 15px;
    padding: 7px 22px;
    font-weight: 600;
    font-size: 12px;
    color: $orange;
    width: 95px;
    // margin: auto;
    margin-bottom: 20px;
    margin-top: 80px;
  }

  h2 {
    color: $white;
  }

  p {
    margin-bottom: 50px;
    color: $white;
  }

  .btn {
    background-color: $orange;
    padding: 16px 46px;
    width: 172px;
    margin-bottom: 140px;

    @include sm {
    }
  }

  // .matchupBreakdowns {
  //   @include sm {
  // height: 110px;
  //   }
  // }
}

.purchase {
  height: 2.5rem;
  background: $orange;
  outline: none;
  border: none;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: $white;
  position: relative;
  overflow: hidden;
  z-index: 10;
  transition: all 0.5s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 100%;
    background: rgb(20, 137, 20);
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    &::before {
      width: 100%;
    }

    box-shadow: -2px 3px 33px 4px rgba(20, 137, 20, 0.2);
    // -webkit-box-shadow: -2px 3px 33px 4px rgba(20, 137, 20, 0.01);
    // -moz-box-shadow: -2px 3px 33px 4px rgba(20, 137, 20, 0.01);
  }
}

.BuyPagePlan {
  background: $white;
  border-radius: 16px;
}

.Blog {
  .slick-slider {
    button {
      background: $orange;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 4px;
    }
  }
}

/* Model Sandbox Page Starts */

.Sandbox {
  padding-bottom: 30px;
  span.parameters {
    font-size: 24px;
    font-weight: 700;
    color: $chineseBlack;
    margin-right: 30px;
  }

  .categories {
    align-items: baseline;
    margin-top: 1rem;

    button {
      font-size: 16px;
      border: 0;
      background-color: transparent;
      color: $fadedGrey;

      &.active {
        padding-bottom: 10px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          // width: 100%;
          background: $orange;
          height: 4px;
          border-radius: 2px;
        }
      }
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;

    .tag {
      background: rgba(242, 107, 11, 0.12);
      border: 1px solid $orange;
      border-radius: 30px;
      color: $orange;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;

      padding: 7px 22px;
      margin-right: 12px;
      margin-bottom: 12px;
      width: 250px;
      white-space: nowrap;
      margin-top: 0px;
      &:disabled {
        color: gray;
        background: rgba(66, 66, 66, 0.12);
        border: 1px solid gray;
        height: 120x;
      }
    }
  }

  .selectedTags {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .selectedTag {
      padding: 4px 12px;

      background: $orange;
      border: 1px solid $orange;
      border-radius: 30px;
      color: $white;
      font-weight: 600;
      font-size: 12px;
      margin-right: 12px;
      width: 200px;
      min-height: 32px;
      max-height: 48px;
      margin-bottom: 12px;
    }

    .emptyTag {
      display: inline-flex;
      margin-right: 12px;
      margin-bottom: 12px;
      width: 200px;
      height: 32px;
      border: 1px dashed $lightGrey;
      border-radius: 30px;
      content: "";
    }
  }

  input.parameters {
    border: 1px solid $lightGrey;
    border-radius: 8px;
  }
}

.item-slider-list {
  padding: 15px;
}

.tag {
  background: $linen;
  border-radius: 15px;
  // padding: 7px 22.5px;
  font-weight: 600;
  font-size: 12px;
  color: $orange;
  width: auto;
  margin-bottom: 20px;
  margin-top: 40px;

  padding: 6px 26px;
  gap: 10px;
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 12px;
}

.reviews {
  display: flex;
  justify-content: space-between;
  overflow: auto;

  @include sm() {
    flex-direction: column;
  }

  .review {
    background: $athensGrey;
    border-radius: 10px;

    width: 380px;
    height: 212px;
    padding: 30px;
    margin-bottom: 120px;

    @include xl {
      min-width: 380px;
      margin-right: 40px;
    }

    @include lg {
      min-width: 380px;
      margin-right: 20px;
    }

    @include sm {
      margin-bottom: 20px;
      width: 327px;
    }

    .twitterLink {
      font-size: 12px;
      color: $fadedGrey;
    }

    img {
      margin-right: 16px;
      width: 36px;
      height: 36px;
    }

    #reviewer {
      border-radius: 50%;
    }

    h3 {
      font-family: var(--font-oswald);
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.signUpBlock {
  background-color: $chineseBlack;
  border-radius: 10px;
  height: 326px;
  margin-bottom: 80px;

  h2 {
    font-family: var(--font-inter);
    font-weight: 800;
    font-size: 36px;
    color: $white;
    text-align: center;

    margin: 60px auto 50px auto;

    @include lg {
      font-size: 24px;
    }

    @include sm {
      font-size: 32px;
    }
  }
}

/* HomePage Ends */
/* Game Page Start */

.gameTable {
  .RankCell span,
  .RankCell small {
    position: initial;
    display: inline-flex;
  }

  // .RankCell span {
  //   margin-right: 21px;
  // }

  th:not(:first-child) div {
    justify-content: center;
  }
}

.rankList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 510px;
  width: 1280px;
  border: none;

  @include xl {
    height: 890px;
    width: auto;
  }

  @include lg {
    height: 830px;
  }

  .rankElement {
    flex: 0 1;
    min-height: 107px;
    width: 620px;
    background-color: $white;
    border-radius: 24px;
    margin-bottom: 20px;
    display: flex;
    @include xl {
      margin: auto;
    }

    @include sm {
      width: 340px;
    }
  }
}

.paginations {
  width: 250px;
  padding: 10px 0px;
  margin: 1rem 0;
  margin-left: 15px;
  .row {
    @media (max-width: 767px) {
      padding: 0.5rem 1rem;
      gap: 1rem;
    }
  }

  .paginationLinkGroup {
    align-items: baseline;

    .active {
      color: $chineseBlack;
      padding-bottom: 10px;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $orange;
        height: 4px;
        border-radius: 2px;
      }
    }
  }

  .paginationLink {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: $fadedGrey;
    margin-right: 24px;
    cursor: pointer;
    position: relative;
  }
}

/* Game Page Ends */

.Notification {
  padding: 1rem;
  margin: 1rem 0rem;
  width: 100%;
  min-height: 200px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  box-shadow: -2px 3px 33px 4px $bronze;
  -webkit-box-shadow: -2px 3px 33px 4px $bronze;
  -moz-box-shadow: -2px 3px 33px 4px $bronze;
  border-color: $bronze;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Notification__Heading {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: center;
    svg {
      font-size: 2rem;
      display: block;
      margin: auto;
      margin-bottom: 0.75rem;
    }
  }

  .Notification__Body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    z-index: -1;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  &.Notification--Success {
    &::before {
      background: rgb(85, 225, 59);
      background: radial-gradient(circle, rgba(85, 225, 59, 0.4) 0%, rgba(85, 225, 59, 0.6) 77%);
    }

    .Notification__Heading,
    .Notification__Body {
      color: darkgreen;
    }
  }

  &.Notification--Warning {
    &::before {
      background: rgb(223, 204, 65);
      background: radial-gradient(circle, rgba(223, 204, 65, 0.4) 0%, rgba(223, 204, 65, 0.6) 77%);
    }

    .Notification__Heading,
    .Notification__Body {
      color: goldenrod;
    }
  }

  &.Notification--Error {
    &::before {
      background: rgb(236, 62, 56);
      background: radial-gradient(circle, rgba(236, 62, 56, 0.4) 0%, rgba(236, 62, 56, 0.6) 77%);
    }

    .Notification__Heading,
    .Notification__Body {
      color: $red;
    }
  }
}

.Banner {
  height: 250px;
  position: relative;
  box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  overflow: hidden;
  padding: 0;
  background-size: cover;

  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60" height="60"><image width="30" height="40" xlink:href="data:image/jpeg;base64,  iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAcWAAAHFgHjmmr+AAADC0lEQVRYhb1Xy42cQBCtXXFfQiADk8ESgJE4mJuROwRC6BAIAYkjl5ZIgM2AzQBnMI5grUKv2ILh07NYLglp6GmqXr16Xd399PHxQT6W5kVMRBkRJUT0SkTvRHTDpyERfSOiNyIaiKju2mbw8XsKIM0LDmpVgHLPOUAywBLgbNc27ksA0ryIOBO8VsgywcMZ2gPQIb75BdCma5vRGwCyZgdV1zbVhnOLcrDj/gBIDRC/wdwdG3cA0rwwCJ4c1RF0cwB3woZDOTKAqPX/CwDInCfEe5StnAvVXK6sa5vbzpwBpRvWIGYAqHkPR14KVkGMlGXr2zQvLFhg/w7sTgk+q3k1av5QcDZkxOy5NC/KjSk19DKAsSUDyIAnJI8G1wa6a6yYhfLTvBjA0JjmRY+VVAsDJSi8ZKyBrm1kBfW8CqArkvIi2RAx6en7j58x0MRXAWwwkqE0ER6CBhxAmgATavqkULrZno14bmd6wbp38J1AfJY+e8QEIAFd4qxXil1bpJ4kzQvC+4glNj2eQmb/VYCNJTrraidUR2COH4P3Edn3W4B4LM2L1wBtMt5qIr4GtUtAASWltPgtc7S9sQj7q8vPx1BziVNDhO75mtuHjBk2ABFBL9H/BMCBRy5X1zYGqy8UEf5TU8tZjGsf6s4IEb5MGjg65fgalJ+BZgLFElDGZrFDmNMy1Fvlo0FD2ecxVEu/X82L0H6dEuLUfwJ8JKr0DRwjaAKnd0FXwbkDRrwJcUdEv5k2wAC1uKk/9oJGKtsBJyGzN1+ZWZ0tDTropINAUWf1HoAsI4xlWEa1Pkx4MBUCQIyADocTK4zrE1GPiVoLPd77r3TKNC8qbFpWjY1YjlOygZpvEPDyiqDl7herMfk9l25uRKDVYme8dDZQdwqjxmKV4FzCRSfE2a68AgJ1d7gVDRvBF3eDu1YMEObggHkUXAJVcvSGD4ftvl5/43s1s2dnBQQqJUtoQMT32NVs5Vgup2wMaD5gSJMR7cilRtV+ZmLPrlzP/xDRC/6W33wRnS4fXiuJiP4CxzLNYSW9Vu4AAAAASUVORK5CYII=" /></svg>');
  background-size: 20px 20px;

  transition: background-size 1s ease-in-out;

  background-repeat: repeat;
  &::before {
    position: absolute;
    content: " ";
    width: 100%;
    backdrop-filter: blur(1px);
    height: 100%;
    background: rgb(247, 203, 169);
    background: linear-gradient(145deg, rgba(247, 203, 169, 1) 16%, rgba(76, 84, 92, 0.31726193895526966) 100%);
  }

  @media (max-width: 767px) {
    width: 95%;
  }
}

.AnimIntro {
  min-height: 450px;
  position: relative;
  //   border: 2px solid gray;

  @keyframes CellSlitIn {
    0% {
      -webkit-transform: translateZ(-800px) rotateX(90deg);
      transform: translateZ(-800px) rotateX(90deg);
      opacity: 0;
    }
    54% {
      -webkit-transform: translateZ(-160px) rotateX(87deg);
      transform: translateZ(-160px) rotateX(87deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(0) rotateX(0);
      transform: translateZ(0) rotateX(0);
    }
  }

  img {
    width: 25px;
    animation: CellSlitIn 0.45s ease-out both;
  }

  @keyframes AnimCellRank {
    0% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes AnimTableIntro {
    0% {
      -webkit-transform: translateZ(0) scale(0.7);
      transform: translateZ(0) scale(0.7);
      opacity: 0;
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px) scale(1);
      transform: translateZ(50px) scale(1);
      opacity: 1;
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }

  @keyframes AnimLineWidth {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes AnimLineHeight {
    0% {
      height: 0%;
    }
    100% {
      height: 100%;
    }
  }

  @keyframes AnimTableRow {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }

  @keyframes AnimTableCell {
    0% {
      -webkit-transform: translateZ(80px);
      transform: translateZ(80px);
      opacity: 0;
      border: 1px solid transparent;
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
      border: 1px solid lightgray;
      border-collapse: collapse;
    }
  }

  opacity: 0;

  animation: AnimTableIntro 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) alternate forwards;
  animation-delay: 0.5s;

  //   background-color: lightgray;
  .line {
    display: block;
    position: absolute;
    box-shadow: 0px 0px 65px -8px rgba(35, 35, 35, 1);
    -webkit-box-shadow: 0px 0px 65px -8px rgb(69, 49, 49);
    -moz-box-shadow: 0px 0px 65px -8px rgba(35, 35, 35, 1);
    z-index: 2;
  }

  .line.lineTop {
    top: 0px;
    height: 5px;
    animation: AnimLineWidth 1s ease-in-out forwards;
    animation-delay: 1.5s;
    // background: $trout;
    background: rgb(208, 124, 59);
    background: linear-gradient(137deg, rgba(208, 124, 59, 1) 0%, rgba(76, 84, 92, 1) 100%);
  }

  .line.lineBottom {
    bottom: 0;
    background: rgb(208, 124, 59);
    background: linear-gradient(137deg, rgba(208, 124, 59, 1) 0%, rgba(76, 84, 92, 1) 100%);
    height: 5px;
    animation: AnimLineWidth 1s ease-in-out forwards;
    animation-delay: 1.5s;
  }

  .line.lineRight {
    width: 5px;
    right: 0;
    background: rgb(208, 124, 59);
    background: linear-gradient(137deg, rgba(208, 124, 59, 1) 0%, rgba(76, 84, 92, 1) 100%);
    animation: AnimLineHeight 1s ease-in-out forwards;
    animation-delay: 1.5s;
  }

  .line.lineLeft {
    width: 5px;
    left: 0;
    background: rgb(208, 124, 59);
    background: linear-gradient(137deg, rgba(208, 124, 59, 1) 0%, rgba(76, 84, 92, 1) 100%);
    animation: AnimLineHeight 1.5s ease-in-out forwards;
    animation-delay: 0.75s;
  }

  .table {
    thead {
      background: rgba(208, 124, 59, 1);
      color: $white;
      th {
        height: 50px;
        text-align: center;
        border: 1px solid rgba(211, 211, 211, 0.503);
        font-weight: 500;
      }
    }

    tbody {
      td {
        min-height: 50px;
        height: 50px;
        opacity: 0;

        text-align: center;
        // border-collapse: collapse;

        animation: AnimTableCell 1s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
        animation-delay: 2s;

        .cellData {
          animation: CellSlitIn 0.45s ease-out both;
          font-weight: 500;
        }

        .cellRank {
          opacity: 0;
          animation: AnimCellRank 0.45s ease-in-out forwards;
          // animation-delay: 5s;
        }

        .cellData.red {
          color: red;
        }
        .cellData.green {
          color: green;
        }
      }

      tr {
        opacity: 0;
        animation: AnimTableRow 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
        &:nth-of-type(even) {
          background-color: $linkWater;
        }
        &:nth-of-type(odd) {
          background-color: $white;
        }
      }
    }
  }
}

//Updating Site Change
.search-results {
  width: 90%;
  margin: auto;
  left: 0;
  right: 0;
  padding: 20px 30px;
  max-height: 304px;
  overflow-y: scroll;
  position: absolute;
  border: 1px;
  gap: 14px;
  isolation: isolate;
  background: $white;
  box-shadow: 2px 4px 30px rgba(107, 108, 114, 0.12);
  border-radius: 8px;
  text-align: left;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  &::-webkit-scrollbar-thumb {
    background: $fadedGrey;
    border-radius: 10px;
    height: 30px;
    width: 2px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }
  .text-left-2 {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    background: transparent;
    text-align: left;
    color: $chineseBlack;
  }
  span {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    border-bottom: 0px;
    color: $chineseBlack;
    margin-bottom: 14px;
  }
}

.contactUs {
  width: 1280px;
  height: 220px;
  margin: auto;
  margin-top: 80px;
  border-radius: 10px;
  background-color: $chineseBlack;
  color: $white;
  display: flex;

  @include sm {
    width: auto;
    height: auto;
    padding: 1.5rem;
    flex-direction: column;
    margin-top: 0;
  }

  h2 {
    color: $white;
    font-size: 36px;
    font-weight: 800;
  }

  p {
    font-weight: 500;
    color: $white;
  }

  .descr {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  a {
    display: flex;
    margin: auto;
    padding: 16px 46px;
    background-color: $white;
    color: $chineseBlack;
  }
}
.build-page {
  padding: 0;
  padding-top: 80px;

  @include xl {
  }

  @include sm {
    padding: 20px 24px;
  }

  h1 {
    font-family: var(--font-inter);
    font-weight: 900;
    font-size: 48px;
    margin-bottom: 24px;

    @include xl {
      font-size: 32px;
    }

    @include lg {
      font-size: 24px;
    }
  }
  p {
    // margin-bottom: 50px;

    @include sm {
      font-size: 14px;
    }
  }
  .btn {
    padding: 16px 20px;
    width: 183px;
  }
  .advanced-data {
    background-color: $lightGrey;
    margin-top: 50px;
  }
  .advanced-data-points {
    margin-top: 1.5rem;
    p {
      margin-bottom: 1rem;
      font-size: 19px;
    }
  }
  .advanced-data-links {
    background-color: $lightGrey;
    padding-bottom: 60px;
    .container {
      margin-top: 70px;

      .links {
        p {
          color: $black;
          margin-bottom: 0px;
          font-size: 19px;
        }
        .link {
          color: $orange;
        }
        @include sm {
          margin-bottom: 30px;
        }
      }
    }
  }

  .premium-section {
    margin-top: 70px;
    .premium-section-header {
      font-size: 19px;
    }
  }
}

.glossary-card:hover {
  p,
  h5,
  h3 {
    color: white;
  }
  background-color: $orange;
  transition-duration: 1s;
  .glossary-target-page {
    color: $blue !important;
  }
}
.glossary-target-page {
  color: $orange;
  &:hover {
    font-weight: bold;
  }
}
.update-plan {
  background-color: $white;

  h1 {
    font-family: var(--font-inter);
    font-weight: 900;
    font-size: 48px;
    margin-bottom: 24px;

    @include xl {
      font-size: 32px;
    }

    @include lg {
      font-size: 24px;
    }

    @include md {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }

  .plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .select-plan-text {
      max-width: 1280px;
      padding-right: 50px;

      @include md {
        padding-right: 20px;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;

    @include md {
      flex-direction: column;
    }
  }

  .card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px 8px;
    text-align: center;
    width: 17rem;
    transition: transform 0.2s;
    display: flex;
    justify-content: space-between;
    background-color: #f4f5f9;
    .price {
      color: #c95944;
    }
    p {
      color: black;
    }
    &:hover {
      transform: scale(1.05);
      background-color: #c95944;

      * {
        color: $white;
      }
    }

    @include md {
      width: 100%;
      margin-bottom: 20px;
      padding: 20px 10px;
    }
  }

  hr {
    margin: 20px 0;
    border: none;
    height: 3px;
    background-color: #ccc;
    width: 15%;

    @include md {
      width: 50%;
    }
  }
  // .card.current {
  //   cursor: not-allowed;
  // }
  .card:hover .icon svg path,
  .card:hover .icon svg rect,
  .card:hover .icon svg polygon,
  .card:hover .icon svg polyline,
  .card:hover .icon svg line {
    stroke: white !important;
  }
}

.update-plan-modal {
  background-color: $white;

  h1 {
    font-family: var(--font-inter);
    font-weight: 900;
    font-size: 48px;
    margin-bottom: 24px;

    @include xl {
      font-size: 32px;
    }

    @include lg {
      font-size: 24px;
    }

    @include md {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }
  a .card {
    width: 100%;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;

    @include md {
      flex-direction: column;
    }
    @include sm {
      flex-direction: row;
    }
  }
  hr {
    margin: 20px 0;
    border: none;
    height: 3px;
    background-color: #ccc;
    width: 15%;

    @include md {
      width: 50%;
    }
  }
  .card:hover .icon svg path,
  .card:hover .icon svg rect,
  .card:hover .icon svg polygon,
  .card:hover .icon svg polyline,
  .card:hover .icon svg line {
    stroke: white !important;
  }
}
.upgrade-to-premium {
  background-color: #181d28;
  * {
    background-color: #181d28;
  }
  .header {
    color: $orange;
  }
  .subheader {
    color: $white;
  }
  h1 {
    font-family: var(--font-inter);
    font-weight: 900;
    font-size: 48px;
    margin: 0 !important;

    @include xl {
      font-size: 32px;
    }

    @include lg {
      font-size: 24px;
    }

    @include md {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }

  .premium-block {
    background-color: #242833;
    border-radius: 5px;
    color: $white;
    padding: 10px;
    margin: 5px;
    * {
      background-color: #242833;
      color: $white;
    }
  }
  .contact {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .contact-us {
    background-color: #c95944;
    border-radius: 3px;
  }
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 200px;
    //animate zoom in and out
    animation: zoomInOut 1s linear infinite;
  }
  @keyframes zoomInOut {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.9);
    }
  }
}
span.search-results__text:hover {
  color: $orange;
}

// RegressionStandings
/*Regression Page Starts */
.tl-fixed-desktop {
  @media (min-width: #{md}) {
    table-layout: fixed;

    thead th {
      min-width: 100px;
    }
  }
}
.RegressionStandings {
  .container-fluid {
    @include container-fluid-lg();

    @media (min-width: #{$lg}) {
      table {
        table-layout: fixed;
      }
    }
  }
  /* Table */
  .table-responsive {
    thead {
      position: sticky;
      top: 0px;
      z-index: 2;
      @media (max-width: 1199px) {
        top: 0;
      }
    }

    .table,
    .teamStanding {
      tr th {
        position: sticky;
      }
      tr td {
        font-family: "Inter";
        vertical-align: middle;
        border: 0;
      }

      td a {
        color: #0f131b;
        font-weight: 700;
        font-size: 16px;

        @include xl {
          font-size: 14px;
        }

        &:hover {
          color: #f26b0b;
          text-decoration: underline;
        }
      }

      tr,
      .thead {
        th {
          position: relative;
          font-size: 14px;
          font-weight: 500;
          vertical-align: middle;
          color: white;
          background-color: #0f131b;
          padding: 20px 7px;
          padding-right: 18px;
          // z-index: 6 !important;
          .fa {
            position: absolute;
            top: 50%;
            right: 10px;
            font-size: 12px;
            margin-left: 5px;
            display: inline-block;
            vertical-align: top;
            margin-top: -7px;
          }
          .rank {
            transition: opacity 0.25s ease-in-out;
            padding: 10px;
            position: absolute;
            bottom: 100%;
            background-color: rgba($color: $white, $alpha: 1);
            left: 0;
            right: 0;
            @include box-shadow(0 0 10px rgba(0, 0, 0, 0.1));
            @include border-radius(5px 5px 0 0);
            // border: 2px solid $orange;
            img {
              width: 100%;
              max-width: 100px;
            }
          }
          .tooltipCon {
            position: absolute;
            bottom: 90%;
            // left: 0;
            right: -45%;
            color: #0f131b;
            background-color: #f2f4f6;
            padding: 0 15px 15px;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            width: 220px;
            text-align: left;
            display: none;
            // overflow-y: auto;
            border: 1px solid #6b6c72;
            border-radius: 8px;
            z-index: 9;
            margin-bottom: 7px;

            @include border-radius(5px 5px 0 0);
            @include box-shadow(0 0 10px rgba(0, 0, 0, 0.1));
            b {
              font-weight: 600;
              margin-bottom: 5px;
              padding: 10px 15px;
              font-size: 14px;
              color: #fff;
              background-color: #0f131b;
              margin-left: -15px;
              margin-right: -15px;
              margin-bottom: 15px;
            }

            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
              background: #888;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }

            &:after {
              // layout
              content: "";
              position: absolute;
              width: 0;
              height: 0;
              // bottom: 100%;
              top: 100%;
              left: 8.5em; // offset should move with padding of parent
              border: 0.75rem solid transparent;
              border-top: none;
              transform: rotate(0.5turn);

              // looks
              border-bottom-color: #fff;
              filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
            }
          }
          &:last-child {
            .tooltipCon {
              left: auto;
              right: 0;
            }
          }
          &:hover {
            .tooltipCon {
              display: block;
            }
          }
        }
      }

      .tbody {
        tr {
          &:nth-of-type(even) {
            background-color: #f2f4f6;
          }
          td {
            font-weight: 400;
            &.center-txt {
              text-align: center;
              vertical-align: middle;
              margin-left: 10px;
            }
            &.bg-red {
              background-color: #ff0000;
            }
            &.bg-green {
              background-color: #00ff04;
            }
            &.bg-ivory {
              background-color: #847b02;
            }
            a {
              text-align: left;
              display: block;
            }
            img {
              max-width: 25px;
              &.scoregraph {
                max-width: 80px;

                @media (max-width: #{$xxl2}) {
                  max-width: 80px;
                }
              }
            }
            @media (max-width: #{$md}) {
              padding: 0.45rem;
              font-size: 13px;
            }
          }
        }
      }
    }
    thead {
      &:hover {
        .rank {
          opacity: 0;
        }
      }
    }

    .playerStatsWrapper {
      overflow: scroll !important;
    }

    .playerStats tr:nth-child(even) {
      background-color: #f2f4f6;
    }

    .playerStats th {
      min-width: 79px;
      max-width: 193px;
      border-right: 1px solid #6b6c72 !important;
    }

    .playerStats td {
      border-right: 1px solid #d0d2d9 !important;
    }

    .allShotTypes {
      @extend .playerStats;

      th {
        min-width: 160px;

        width: auto !important;
      }
    }

    .shottypeAnalysis {
      border-collapse: separate;
      border-spacing: 0;

      & th {
        font-weight: 700 !important;

        &:first-child {
          width: 122px;
          max-width: 96px;
        }
      }

      // & td.RankCell {
      //   & span {
      //     @include xl {
      //       margin-right: 6px;
      //     }
      //   }
      // }
    }

    .miscAnalysis {
      border-collapse: separate;
      border-spacing: 0;

      @extend .playerStats;

      th {
        font-weight: 700 !important;
      }
    }

    .teamSchedule tr:last-child td:nth-child(4) {
      border-bottom-right-radius: 0;

      @include xl {
        border-bottom-right-radius: 24px;
      }

      @include lg {
        border-bottom-right-radius: 0;
      }
    }

    .teamSchedule td,
    .teamSchedule th {
      // min-width: 78px;
      max-width: 145px;
      box-sizing: content-box;
    }

    .teamSchedule th:nth-child(4) {
      border-top-right-radius: 0;
      @include xl {
        border-top-right-radius: 24px;
      }

      @include lg {
        border-top-right-radius: 0;
      }
    }
  }
  .table-responsive {
    @media (max-width: #{$md}) {
      padding-top: 4rem;
      transform: translateY(-35px);
    }

    @media (max-width: 813px) and (orientation: landscape) {
      padding-top: 125px;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      padding-top: 150px;
    }

    @include xl {
      overflow: unset;
    }

    @include lg {
      overflow: scroll;
    }

    border-collapse: collapse;
  }
  table {
    tr {
      td {
        text-align: center;
        vertical-align: middle;
        position: relative;
        // right: 20%;
        .arrow {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 15px;
          margin: auto;

          @media (max-width: 767px) {
            margin-left: 1.5rem;
            font-size: 1rem;
          }
          font-size: 1.15rem;
          margin-left: 2rem;
          &.up {
            color: green;
            -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          }
          &.down {
            color: red;
            -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          }
        }
        small,
        span {
          align-items: center;
          display: inline-flex;
        }
        small {
          border-radius: 4px;
          color: #000;
          font-weight: 600;
          height: 30px;
          justify-content: center;
          margin: auto;
          text-align: center;
          width: 30px;
          margin-left: 4px;
        }
      }
    }
  }
  table {
    position: relative;
    border-collapse: collapse;
  }

  th {
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
}
/*Regression Page Ends */
/*matchup output page starts */

.MiscStats.Output {
  background-color: $white;
  @include sm() {
    .first {
      order: 2;
    }
    .second {
      order: 1;
    }

    .third {
      order: 3;
    }
  }
  .matchup-preview-tables {
    table {
      // display: inline-block;
      padding: 1rem 2rem;
      // background: #fff7f1;
      margin-bottom: 1rem;
      border-radius: 4px;
      // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      tr:nth-child(2) td {
        padding-top: 0;
      }
      tr:nth-child(even) {
        background-color: $white;
      }
      td {
        padding: 0.4rem;
        text-align: center;
        // border-bottom: 1px solid #dcdcdc5c;
        span {
          min-width: 42px;
          font-size: 13px;
        }
        small {
          // background: white !important;
          border: 1px solid #d3cccc;
        }
      }
    }
  }
  tbody {
    h5 {
      padding: 0.5rem;
      background-color: #f26b0b;
      color: white;
    }
  }
  a:hover {
    cursor: pointer;
    color: #ef6c00 !important;
    text-decoration: underline;
    h3 {
      color: #ef6c00;
    }
  }
}

.table-responsive.summary {
  td.RankCell {
    span,
    small {
      display: inline-flex;
      position: relative;
    }
    // span {
    //   margin-right: 1rem;
    // }
  }
}

/*matchup output page ends */

#live-query-tool {
  .live-query-handle {
    font-size: 10px;
  }
  .form-icon {
    color: #f26b0b;
  }
}
