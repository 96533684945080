/* --- btn override --- */
.btn {
  // padding: 10px 15px 7px;
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;
  @include transition(0.5s);
  @include border-radius(10px);
}

.btn2 {
  // padding: 10px 15px 7px;
  font-size: 15px;
  font-weight: 400;
  //white-space: nowrap;
  cursor: pointer;
  @include transition(0.5s);
  @include border-radius(10px);
}

/* --- btn-primary --- */
.btn-primary {
  color: $chineseBlack;
  background-color: transparent;
  border-color: $chineseBlack;
  &.active,
  &:active,
  &.focus,
  &:focus,
  &.active.focus,
  &.active:focus,
  &.active:hover,
  &:active.focus,
  &:active:focus,
  &:active:hover,
  &:hover {
    color: $chineseBlack;
    background-color: transparent;
    border-color: $chineseBlack;
    @include box-shadow(none);
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    border-color: transparent;
    @include box-shadow(none);
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include box-shadow(none);
  }
}

/* --- btn-secondary --- */
.btn-secondary {
  color: $white;
  background-color: $chineseBlack;
  border-color: transparent;
  &.active,
  &:active,
  &.focus,
  &:focus,
  &.active.focus,
  &.active:focus,
  &.active:hover,
  &:active.focus,
  &:active:focus,
  &:active:hover,
  &:hover {
    color: $white;
    background-color: $graniteGrey;
    border-color: transparent;
    @include box-shadow(none);
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    border-color: transparent;
    @include box-shadow(none);
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include box-shadow(none);
  }
}

/* --- btn-secondary --- */
.btn-orange {
  color: $white;
  background-color: $orange;
  border-color: transparent;
  &.active,
  &:active,
  &.focus,
  &:focus,
  &.active.focus,
  &.active:focus,
  &.active:hover,
  &:active.focus,
  &:active:focus,
  &:active:hover,
  &:hover {
    color: $white;
    background-color: $chineseBlack;
    border-color: transparent;
    @include box-shadow(none);
  }
  &:hover {
    border: 1px solid $orange;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    border-color: transparent;
    @include box-shadow(none);
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include box-shadow(none);
  }
}
.btn-green {
  color: $white;
  background-color: #719a3a;
  border-color: transparent;
  &.active,
  &:active,
  &.focus,
  &:focus,
  &.active.focus,
  &.active:focus,
  &.active:hover,
  &:active.focus,
  &:active:focus,
  &:active:hover,
  &:hover {
    color: $white;
    background-color: $chineseBlack;
    border-color: transparent;
    @include box-shadow(none);
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    border-color: transparent;
    @include box-shadow(none);
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include box-shadow(none);
  }
}

.btn-dummy {
  width: 246px;
  height: 56px;
  color: $white;
  background-color: $chineseBlack;
  border-color: transparent;
  font-size: 16px;
  margin-top: 40px;
  &.active,
  &:active,
  &.focus,
  &:focus,
  &.active.focus,
  &.active:focus,
  &.active:hover,
  &:active.focus,
  &:active:focus,
  &:active:hover,
  &:hover {
    color: $white;
    background-color: $graniteGrey;
    border-color: transparent;
    @include box-shadow(none);
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    border-color: transparent;
    @include box-shadow(none);
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include box-shadow(none);
  }
}

/* --- btn-outline-primary --- */
.btn-outline-primary {
  color: $orange;
  border: 1px solid $orange;
  background-color: $white;
  &.active,
  &:active,
  &.focus,
  &:focus,
  &.active.focus,
  &.active:focus,
  &.active:hover,
  &:active.focus,
  &:active:focus,
  &:active:hover,
  &:hover {
    color: $white;
    background-color: $orange;
    border-color: transparent;
    @include box-shadow(none);
  }
  &:not([disabled]):not(.disabled).active,
  &:not([disabled]):not(.disabled):active {
    color: $white;
    border-color: transparent;
    @include box-shadow(none);
  }
  &:not([disabled]):not(.disabled).active:focus,
  &:not([disabled]):not(.disabled):active:focus {
    box-shadow: none;
  }
}

.btn-tab {
  background-color: $white;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 24px;
  margin-right: 10px;
}

.toggler-group {
  background-color: $chineseBlack;
  border-radius: 24px;
  display: inline-flex;

  & .btn {
    // width: 93px;
    color: $white;
    background: $chineseBlack;
    border-radius: 24px;
    padding: 8px 14px;
    font-size: 14px;
    margin: 4px;
  }

  & .active {
    background-color: $orange !important;
  }
}
.btn-gradient {
  background-size: 150% auto;
  background-image: linear-gradient(to right, $trout 0%, $selago 50%, $bronze 100%);
  transition: 0.5s;
  &:hover {
    background-position: right center;
  }
}

.btn-group-justified {
  .btn-primary {
    opacity: 0.6;
    border: 2px solid transparent;
    background: $orange !important;
  }
  .active {
    opacity: 1;
    -webkit-animation: pulse 1s ease-in-out;
    animation: pulse 1s ease-in-out;
    color: $white;
    background-color: $orange;
    // border: 2px solid $darkbgcolor !important;
  }
}

.btn-group {
  .btn,
  .btn-primary {
    opacity: 0.6;
    border: 2px solid transparent;

    // background: $orange !important;
  }

  .active {
    opacity: 1;
    -webkit-animation: pulse 1s ease-in-out;
    animation: pulse 1s ease-in-out;
    border: 2px solid $darkbgcolor !important;
  }

  @include md {
    .btn {
      font-size: 90%;
      flex: 1;
      margin: auto;
      min-width: 5.4rem;
      //padding: 3vmin;
      //width: fit-content;
      //max-width: 10vmin;
    }
  }

  @include sm {
    margin: auto;
  }
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $sonicSilver;
  border-color: $sonicSilver;
  cursor: default;
}
.btn-space {
  margin-right: 20px;
}

.threshold-btn {
  color: $white;
  border: 1px solid $white;

  width: 30px;
  border-radius: 4px;

  @include sm {
    border: 1px solid $manatee;
    color: $manatee;
  }
}

.threshold-btn-active {
  background-color: $blazeOrange;
  border: 1px solid $blazeOrange;
  color: $white;
}
.calendarBtns {
  height: 50px;
  width: 50px;
  padding-top: 12px;

  @include sm {
    height: 30px;
    width: 30px;
    padding-top: 3px;
  }
}
// reggression stats
.RegressionStandings {
  .SearchInput {
    .form-input {
      margin-bottom: 0;
    }
    .search-results {
      position: absolute;
      right: 20px;
      margin-top: 2px;
      @media (max-width: 380px) {
        margin-top: 75px;
      }
      border-radius: 8px;
      width: 330px;
      padding: 6px;
      z-index: 11;
      h6 {
        line-height: 35px;
        background: #0f131b;
        padding: 0px 10px;
        color: white;
        border: none;
        border-radius: 8px;
        outline: none;
        box-sizing: border-box;

        font-family: "Inter";
        font-weight: 700;
        font-size: 14px;

        // padding: 0px 7px;
      }

      span {
        position: relative;
        padding: 0px 10px;
        line-height: 35px;
        color: black;
        text-align: left;
        border-bottom: 1px solid gray;
        cursor: pointer;
        display: block;

        &:hover {
          background: gray;
        }
      }

      span:nth-last-of-type(2),
      span:last-child {
        border-bottom: none;
      }
    }
  }
  .btn-group {
    max-height: 45px;
    background: black !important;
    border-radius: 24px !important;
    .btn,
    .btn-primary {
      // background: black !important;
      opacity: 0.6;
      color: white;
    }

    .active {
      margin-left: 4px;
      margin-right: 4px;
      margin-top: 4px;
      max-height: 37px;
      display: flex;
      align-items: center;
      opacity: 1;
      -webkit-animation: pulse 1s ease-in-out;
      animation: pulse 1s ease-in-out;
      border-radius: 24px !important;
      background: #f26b0b !important;
    }

    @include md {
      .btn {
        max-width: 50%;
      }
    }

    // To be used when button groups contains for multiple buttons
    &.btn-group--responsive {
      @include sm {
        gap: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        max-height: unset;
        .btn {
          border-radius: 24px !important;
          width: auto;
          display: inline-block;
        }
      }
    }
  }
}
.show-play {
  &.active {
    background-color: $orange !important;
    color: $white;
  }
}

.toggler-checkbox input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.toggler-checkbox .toggle {
  position: relative;
  display: block;
  width: 30px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}
.toggler-checkbox .toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 26px;
  height: 14px;
  display: block;
  background: #9a9999;
  border-radius: 8px;
  transition: background 0.2s ease;
}
.toggler-checkbox .toggle span {
  position: absolute;
  top: 2px;
  left: 0;
  width: 15px;
  height: 16px;
  display: block;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
  transition: all 0.2s ease;
}
.toggler-checkbox .toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -11px;
  width: 26;
  height: 26;
  background: $bronze;
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.toggler-checkbox [id^="cbx_"]:checked + .toggle:before {
  background: #fc65007a;
}
.toggler-checkbox [id^="cbx_"]:checked + .toggle span {
  background: $orange;
  transform: translateX(15px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}
.toggler-checkbox [id^="cbx_"]:checked + .toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.star-checkbox.star {
  visibility: hidden;
  cursor: pointer;
}
.star-checkbox.star:before {
  content: "\2606";
  font-size: 2.4rem;
  position: absolute;
  visibility: visible;
}
.star-checkbox.star:checked:before {
  content: "\2605";
  position: absolute;
  color: $orange;
  /* background:red; */
}
.blink {
  animation: blinker 1.1s linear infinite;
}
.blink.circle {
  padding: 0.3rem;
  border-radius: 50%;
  background: $orange;
  color: $white;
  font-size: 0.6rem;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
button[disabled] {
  cursor: not-allowed !important;
  opacity: 0.5;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: $orange;
  color: $white;
}
.btn.insights {
  position: absolute;
  bottom: 2rem;
  left: 2.5rem;
  font-size: 1.8rem;
  @include sm {
    bottom: 0.5rem;
    left: 1rem;
    font-size: 1.4rem;
  }
}
