@import "variable";
@import "mixin";
@import "component";
@import "layouts";
@import "pages";
@import "vendors";
@import "button";
@import "side";
@import "icon";
@import "slick";
@import "form";
@import "tooltip";
@import "modal";
@import "pagination";
@import "react-override";
@import "gamecard";
@import "rdp";
@import "march-madness";

.cursor-pointer {
  &:hover {
    cursor: pointer;
    color: $orange;
    transition: all ease-in-out 0.2s;
  }
}

// .btnloader {
//   background-image: linear-gradient(to right, #ff416c 0%, #ff4b2b 100%);
//   justify-content: center;
//   text-align: center;
//   padding: 5px;
//   border-radius: 12px;
//   font-weight: 600;
//   border: none;

//   g {
//     stroke-width: 4;
//   }
// }

// .QuoteCard {
//   @include card_base;
//   min-height: 250px;
//   max-width: 600px;
//   margin: 2rem;
// }

// .clip-slide-enter {
//   clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
//   opacity: 0;
// }

// .clip-slide-enter-active {
//   opacity: 1;
//   clip-path: polygon(5% 1%, 100% 0%, 95% 100%, 0% 100%);
// }

// .clip-slide-exit {
//   opacity: 1;
//   clip-path: polygon(5% 1%, 100% 0%, 95% 100%, 0% 100%);
// }

// .clip-slide-exit-active {
//    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
//   opacity: 0;
// }

// svg {
//   transform: rotate(-90deg);
//   width: 160px;
//   height: 160px;

//   @media (max-width: 540px) {
//     display: none;
//   }
// }

/* Custom searchable dropdown*/
// #myInput {
//     box-sizing: border-box;
//     background-position: 14px 12px;
//     background-repeat: no-repeat;
//     font-size: 16px;
//     padding: 14px 20px 12px 45px;
//     border: none;
//     border-bottom: 1px solid #ddd;
//     width: inherit;
// }

// .sdropdown {
//     position: relative;
// }

// Game card at the top of the brackt modal popup

// Styles for react-day-picker in Score Page

// Brackets

/*
 *  Flex Layout Specifics
*/
