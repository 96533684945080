/* Slick Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  .slick-track,
  .slick-list {
    transform: translate3d(0, 0, 0);
  }
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.dragging {
    cursor: move;
    cursor: grab;
    cursor: -webkit-grab;
  }
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
.slick-loading {
  .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: 0;
  display: none;
  &.slick-loading img {
    display: none;
  }
  &.dragging img {
    pointer-events: none;
  }
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background-color: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 9;
  &:hover,
  &:focus {
    outline: none;
    &:before {
      opacity: 1;
    }
  }
  &.slick-disabled:before {
    opacity: 1;
  }
  &:before {
    @include font-awesome;
    font-size: 20px;
    line-height: 1;
    color: $black;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.slick-prev {
  left: 15px;
  &:before {
    content: "\f104";
  }
}
[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
  &:before {
    content: "";
  }
}

.slick-next {
  right: 15px;
  &:before {
    content: "\f105";
  }
}
[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
  &:before {
    content: "";
  }
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-dots {
  position: absolute;
  bottom: -55px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    &.slick-active button:before {
      color: red;
      opacity: 1;
      content: "\f192";
    }
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover,
      &:focus {
        &:before {
          opacity: 1;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "\f111";
        width: 20px;
        height: 20px;
        font-family: fontawesome;
        font-size: 23px;
        line-height: 20px;
        text-align: center;
        color: $black;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}

.slick-list-custom {
  .slick-list {
    // overflow-x: clip !important;
    // overflow-x: hidden !important;
    // height: 400px!important;
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    right: -40px;
    background-color: $black;
    border-radius: 20px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;

    &:before {
      color: $white;
    }
  }

  .slick-prev {
    left: -40px;
  }
}
