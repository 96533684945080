.predictive-tools-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  .pt-header {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .section {
    display: flex;
    border: 1px solid red;
    width: 100%;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    border: 1px solid blue;
  }

  .tier {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.predictive-tools-section-title {
  color: #0f131b;
  font-weight: 700;
  font-size: 24px;
}

.predictive-tools-tier-title {
  color: #0f131b;
  font-weight: 600;
  font-size: 22px;
}

.predictive-tools-section-description {
  color: #6b6c72;
  font-weight: 500;
  font-size: 16px;
}
