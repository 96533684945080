// Media Query Variables
$xs: 374px;
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1440px;
$xxl2: 1440px;

@mixin xs {
  @media (max-width: #{$xs}) {
    @content;
  }
}
//  small devices
@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: #{$md}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}
// Extra large devices
@mixin xl {
  @media (max-width: #{$xl}) {
    @content;
  }
}

//Width: 1440px
@mixin xxl2 {
  @media (max-width: #{$xxl2}) {
    @content;
  }
}

.hl-block {
  height: 100%;
  width: 100%;
}
.title-block {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 100px 0 60px 0;

  @include sm {
    max-width: 300px;
    margin: auto;
    padding-bottom: 30px !important;
  }
}
.title {
  font-family: var(--font-oswald);
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 150%;
  color: #000000;

  @include xl {
    font-size: 42px;
  }

  @include sm {
    font-size: 28px !important;
  }
}
.orange-line {
  background-image: url("../../assets/images/orange-line.png");
  padding-bottom: 23px;
  width: 100%;
  background-color: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 12px;
  padding: 0 0 7px;
}
.hl-inner {
  height: 50rem;
  display: flex;
  position: relative;

  @include xl {
    margin-bottom: 140px;
  }

  @include sm {
    height: 1050px;
  }
}
.orange-blot-hl {
  width: 1px;
  height: 1px;
  position: relative;
  display: initial;
  z-index: -1;
  box-shadow: 0px 0px 200px 200px rgba(242, 107, 11);
  opacity: 20%;
  top: 350px;
  left: 750px;

  @include lg {
    left: 470px;
  }

  @include sm {
    left: 110px;
  }
}
.point-1 {
  position: absolute;
  top: 10%;
  // left: -4%;
  background-image: url("../../assets/images/hp1.png");
  background-size: cover;
  height: 520px;
  width: 520px;
  border-radius: 50%;
  & .emoji {
    top: 50px;
    left: 420px;
    align-items: center;
    justify-content: center;

    @include xl {
      width: 100px;
      height: 100px;
      font-size: 44px;
    }

    @include lg {
      top: 30px;
      left: 350px;
    }

    @include sm {
      left: 260px;
    }
  }

  @include lg {
    position: absolute;
    width: 421px;
    height: 422px;
    top: 55px;
  }

  @include sm {
    width: 343px;
    height: 343px;
  }
}
.point-2 {
  position: absolute;
  left: 65%;
  background-image: url("../../assets/images/hp2.png");
  background-size: cover;
  height: 25rem;
  width: 25rem;
  border-radius: 50%;

  @include xl {
    top: 100px;
    left: 525px;
  }

  @include lg {
    position: absolute;
    width: 306px;
    height: 317px;
    left: 403px;
    top: 295px;
  }

  @include sm {
    width: 343px;
    height: 343px;
    left: 0;
    top: 425px;
  }

  & .emoji {
    top: 30px;
    left: 310px;
    align-items: center;
    justify-content: center;

    @include xl {
      left: 310px;
      width: 94px;
      height: 94px;
      font-size: 40px;
    }

    @include lg {
      top: 20px;
      left: 230px;
    }

    @include sm {
      left: 250px;
    }
  }
}
.point-3 {
  position: relative;
  left: 43%;
  top: 55%;
  background-image: url("../../assets/images/hp3.png");
  background-size: cover;
  height: 22rem;
  width: 22rem;
  border-radius: 50%;

  @include xl {
    top: 500px;
  }

  @include lg {
    width: 295px;
    height: 297px;
    position: absolute;
    left: 110px;
    top: 485px;
  }

  @include sm {
    width: 343px;
    height: 343px;
    left: 0;
    top: 785px;
  }

  & .little-emoji {
    top: 30px;
    left: 280px;

    @include xl {
      align-items: center;
      justify-content: center;
      width: 66px;
      height: 66px;
    }

    @include lg {
      top: 20px;
      left: 230px;
    }

    @include sm {
      left: 250px;
      width: 70px;
      height: 70px;
      justify-content: center;
      align-items: center;
    }
  }
}
.emoji {
  font-size: 40px;
  position: relative;
  display: inline-flex;
  border-radius: 50%;
  padding: 5px 15px;
  background-color: rgba(242, 244, 246, 0.3);
  backdrop-filter: blur(32px);
}
.little-emoji {
  font-size: 30px;
  position: relative;
  display: inline-flex;
  border-radius: 50%;
  padding: 5px 15px;
  background-color: rgba(242, 244, 246, 0.3);
  backdrop-filter: blur(32px);
}
.hl-logo1 {
  height: 118px;
  width: 120px;
  position: absolute;
  z-index: 1;
  left: 200px;
  top: 80px;
  background-image: url("../../assets/images/hl-logo1.png");
  background-size: cover;

  @include lg {
    left: 160px;
    top: 70px;
  }

  @include sm {
    width: 80px;
    height: 80px;
    top: 50px;
    left: 130px;
  }
}
.hl-logo2 {
  height: 100px;
  width: 160px;
  position: absolute;
  z-index: 1;
  left: 122px;
  top: 80px;
  background-image: url("../../assets/images/hl-logo2.png");
  background-size: cover;

  @include xl {
    left: 125px;
  }

  @include lg {
    left: 75px;
    top: 50px;
  }

  @include sm {
    width: 100px;
    height: 62px;
    left: 125px;
    top: 60px;
  }
}
.hl-logo3 {
  height: 160px;
  width: 160px;
  position: absolute;
  z-index: 1;
  left: 95px;
  top: 74px;
  background-image: url("../../assets/images/hl-logo3.png");
  background-size: cover;

  @include xl {
    left: 95px;
  }

  @include lg {
    left: 60px;
    top: 50px;
  }

  @include sm {
    height: 81px;
    width: 100px;
    left: 125px;
  }
}

.hl-text {
  position: absolute;
  font-family: var(--font-inter);
  z-index: 1;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: white;

  @include lg {
    font-size: 18px;
  }

  @include sm {
    font-size: 16px;
  }
}
.hl-text-1 {
  width: 400px;
  top: 245px;
  left: 60px;

  @include lg {
    top: 235px;
    left: 10px;
  }

  @include sm {
    top: 165px;
    left: 20px;
    width: 300px;
  }
}
.hl-text-2 {
  width: 300px;
  top: 255px;
  left: 60px;

  @include lg {
    top: 195px;
    left: 0px;
  }

  @include sm {
    left: 20px;
  }
}
.hl-text-3 {
  width: 250px;
  top: 225px;
  left: 55px;

  @include lg {
    top: 190px;
    left: 20px;
  }

  @include sm {
    top: 195px;
    left: 40px;
  }
}
.orange-bold {
  font-weight: 700;
  color: #f26b0b;
}
