// Media Query Variables
$xs: 374px;
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1440px;
$xxl2: 1440px;

@mixin xs {
  @media (max-width: #{$xs}) {
    @content;
  }
}
//  small devices
@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: #{$md}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}
// Extra large devices
@mixin xl {
  @media (max-width: #{$xl}) {
    @content;
  }
}

//Width: 1440px
@mixin xxl2 {
  @media (max-width: #{$xxl2}) {
    @content;
  }
}

.title-block {
  width: 100%;
  display: flex;
  justify-content: center !important;
  padding: 100px 0 60px 0;
}
.title {
  font-family: var(--font-oswald);
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 150%;
}
.orange-line {
  background-image: url("../../assets/images/orange-line.png");
  padding-bottom: 23px;
  width: 100%;
  background-color: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 12px;
  padding: 0 0 7px;
}

.tab-bar {
  display: flex;
  padding-bottom: 40px;
}
.tab-item-2 {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  border-bottom: 4px solid #f26b0b;
  padding-bottom: 10px;
  color: #0f131b;
  margin-right: 24px;
}
.tab-item-1 {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6b6c72;
}

.table-inner {
  border: 1px solid #d0d2d9;
  border-radius: 24px;
  overflow: hidden;

  @include lg {
    display: none;
  }
}
table {
  width: 100%;
}
.table-inner th {
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  background-color: #0f131b;
  text-align: center;
  padding: 16px 15px;
  white-space: nowrap;
  &:first-child {
    padding-left: 40px;
    @include xl {
      padding-left: 20px;
    }
  }
  &:last-child {
    padding-right: 40px;
  }

  @include xl {
    font-weight: 600;
    padding: 16px 5px;

    &:nth-child(2) {
      min-width: 238px;
    }

    &:nth-child(3) {
      max-width: 71px;
    }
  }
}
.table-inner td {
  padding: 11px 15px;
  text-align: center;
  &:first-child {
    padding-left: 40px;
    padding-right: 0;
    padding-top: 30px;
    vertical-align: top;

    @include xl {
      padding-left: 20px;
    }
  }
  &:last-child {
    padding-right: 40px;
  }
}

.num-td {
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 16px;
  color: #6b6c72;
}
.team-block {
  margin-right: 20px;
  display: flex;
}
.team-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.team-name {
  font-family: var(--font-inter);
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  color: #0f131b;

  @include xl {
    font-size: 14px;
  }
}
.team-location {
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 14px;
  color: #6b6c72;
  text-align: left;
  margin-bottom: 0;
}
.cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.value-td {
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 16px;
  color: #0f131b;

  @include xl {
    font-size: 14px;
  }
}
.num-plate {
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 14px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  padding: 3px 9px;
  margin-left: 10px;

  @include xl {
    width: 24px;
    height: 24px;
    padding: 0;
  }

  @include sm {
    width: 24px;
    height: 24px;
    padding: 0;
    margin-left: 11px;
  }
}
.green-plate {
  color: #0f131b;
  background: rgba(101, 185, 61, 0.4);
}
.yellow-plate {
  color: #0f131b;
  background: rgba(101, 185, 61, 0.08);
}
.orange-plate {
  color: #0f131b;
  background: rgba(234, 77, 64, 0.2);
}
.red-plate {
  color: #0f131b;
  background: rgba(234, 77, 64, 0.6);
}
.record-value {
  font-family: var(--font-inter);
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
}
.positive {
  color: #237a09;
}
.negative {
  color: #d82011;
}

.mobile-table-inner {
  // border: 1px solid #D0D2D9;
  // border-radius: 24px;
  display: flex;
  margin-right: 12px;
  overflow: auto;
  border-radius: 24px;
  // display: none;

  // @include md {
  //     display: none !important;
  // }

  // @include lg {
  //     display: flex;
  // }
}

.mobile-table-inner table {
  margin-right: 12px;
  border: 1px solid #d0d2d9;
  border-radius: 24px;
  border-collapse: separate;
  border-spacing: 0;
}

.mobile-table-inner th {
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background-color: #0f131b;
  border-radius: 24px 24px 0 0;
  padding: 16px 30px;
  white-space: nowrap;
}

.mobile-table-inner tr td {
  padding: 12px 15px;
  text-align: center;
}

.mobile-table-inner .team-block {
  margin-right: 20px;
  display: flex;
}

.mobile-table-inner .num-td {
  padding-left: 16px;
  padding-right: 15px;
  padding-top: 15px;
}

.mobile-table-inner .th-value {
  font-size: 12px;
  font-weight: 700;
}

.mobile-table-inner .cell {
  display: flex;
  flex-direction: column;
}
