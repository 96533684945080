// @import '../index';
// Media Query Variables
$xs: 374px;
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1440px;
$xxl2: 1440px;

@mixin xs {
  @media (max-width: #{$xs}) {
    @content;
  }
}
//  small devices
@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: #{$md}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}
// Extra large devices
@mixin xl {
  @media (max-width: #{$xl}) {
    @content;
  }
}

//Width: 1440px
@mixin xxl2 {
  @media (max-width: #{$xxl2}) {
    @content;
  }
}

button {
  background: none;
  border: none;
}
.article-block-general {
  margin-top: 140px;
  // padding-bottom: 160px;
}
.hide-desktop {
  display: none;
}

button {
  background: none;
  border: none;
}
.article-block-general {
  margin-top: 140px;
  // padding-bottom: 160px;
}

.list-tab-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-bottom: 10px;
  padding-top: 10px;
  .active {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    padding-bottom: 10px;
    color: #0f131b;
    margin-right: 24px;
    white-space: nowrap;
    max-width: 50vw;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #f26b0b;
      height: 4px;
      border-radius: 2px;
    }
  }
}
.list-tab {
  @media (min-width: #{$lg}) {
    overflow: visible;
  }
  display: flex;
  align-items: baseline;
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: auto;

  .active {
    font-family: var(--font-inter);
    font-style: normal;
    padding-bottom: 10px;
    color: #0f131b;
    margin-right: 24px;
    white-space: nowrap;
    max-width: 50vw;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #f26b0b;
      height: 4px;
      border-radius: 2px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .hide-desktop {
    display: block;
    color: black;
  }

  .list-tab {
    display: none;
  }

  .img-article-1 {
    justify-self: center;
  }
  .article-block-general {
  }
}

@media only screen and (max-width: 796px) {
  .hide-desktop {
    margin-bottom: 20px;
  }

  .remove-padding-on-mobile {
    padding: 0;
  }
}
.list-item {
  font-family: var(--font-inter);
  margin-right: 24px;
  line-height: 150%;
  padding: 0;
  white-space: nowrap;
}

.title-block {
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 60px 0 60px 0;
}
.jc-sb {
  padding-top: 70px !important;
  justify-content: space-between !important;
}
.show-more {
  display: flex;
  align-items: baseline;
  width: 115px;
  justify-content: space-between;

  @include sm {
    display: none;
  }
}
.sm-span {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #f26b0b;
}
.title {
  font-family: var(--font-oswald);
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 150%;
  color: #000000;

  @include xl {
    font-size: 42px;
  }

  @include sm {
    font-size: 28px !important;
  }
}
.orange-line {
  // background-image: url(../../images/orange-line.png);
  padding-bottom: 23px;
  width: 100%;
  background-color: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 12px;
  padding-bottom: 8px;
}
.orange-blot-article {
  width: 220px;
  height: 220px;
  position: absolute;
  display: initial;
  z-index: -1;
  opacity: 20%;
  filter: blur(220px);
  transform: rotate(62.36deg);
  top: 0;
  left: 60px;
  box-shadow: 0px 0px 80px 80px rgb(242 107 11);
}

.article-featured-image {
  background-position: center center;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 700px;
  height: auto;
  border-radius: 16px;
  margin-right: 30px;

  @media (max-width: 1248px) {
    width: 600px;
    height: auto;
  }

  @media (max-width: 1077px) {
    width: 550px;
    height: auto;
  }

  @media (max-width: 1017px) {
    width: 450px;
    height: auto;
  }

  @media (max-width: 487px) {
    width: 350px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  // @include lg {
  //   width: 450px;
  //   height: 450px;
  // }

  // @include sm {
  //   width: 327px;
  //   height: 310px;
  // }
}

.featured-image-tag {
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 14px;
  padding: 12px 24px;
  border-radius: 100px;
  border: none;
  display: inline-flex;
  position: relative;
  left: 20px;
  top: -60px;

  // @media (max-width: 1248px) {
  //   left: 20px;
  // top: 520px;
  // }

  // @media (max-width: 1077px) {
  //   left: 20px;
  // top: 480px;
  // }

  // @media (max-width: 1017px) {
  //   left: 5px;
  //   top: 380px;
  // }

  // @media (max-width: 487px) {
  //   left: 5px;
  //   top: 240px;
  //   margin-bottom: 5px;
  // }
}
.img-article-1 {
  position: relative;
  width: 300px;
  height: 220px;
  background-position: center center;
  background-clip: border-box;
  // background-image: url(../../images/article1.png);
  background-size: cover !important;
  border-radius: 24px;
  // padding: 40px 30px;

  @include xl {
    width: 300px;
    height: 220px;
  }

  // @include lg {
  //   width: 334px;
  //   height: 350px;
  // }

  // @include sm {
  //   width: 327px;
  //   height: 310px;
  // }
}

.article-tag {
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 14px;
  padding: 12px 24px;
  border-radius: 100px;
  border: none;
  display: inline-flex;
  margin-bottom: 10px;

  &:not(:first-child) {
    margin-left: 8px;
  }

  @include lg {
    padding: 6px 16px;
  }

  @include sm {
    font-size: 12px;
    padding: 6px 16px;
  }
}
.publication-date {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6b6c72;
  margin-top: 20px;
  margin-bottom: 20px;

  @include xl {
    margin-top: 20px !important;
    margin-bottom: 8px;
  }

  @include lg {
    font-size: 14px;
  }

  @include sm {
    margin-top: 0;
  }
}
.header-article {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 12px;
  color: #0f131b;

  @include xxl2 {
    // padding: 0 40px;
  }

  @include xl {
    font-size: 22px;

    margin-bottom: 8px;
  }

  @include lg {
    font-size: 20px;
  }
}

.description-article {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6b6c72;
  padding-right: 30px;

  @include xxl2 {
    // padding: 0 40px;
  }
}
.img-article-2 {
  // background-image: url(../../images/article2.png);
  background-position: center center;
  background-size: cover !important;
  width: 180px;
  height: 180px;
  border-radius: 16px;
  margin-right: 30px;

  @include xl {
    min-width: 140px;
    height: 140px;
    margin-right: 0px;
  }

  @include lg {
    width: 120px;
    height: 120px;
  }

  @include sm {
    max-width: 96px;
    max-height: 96px;
  }
}
.img-article-3 {
  // background-image: url(../../images/article3.png);
  background-position: center center;
  background-size: cover !important;
  width: 180px;
  height: 180px;
  border-radius: 16px;
  margin-right: 30px;

  @include xl {
    min-width: 140px;
    height: 140px;
    margin-right: 0px;
  }

  @include sm {
    max-width: 96px;
    max-height: 96px;
  }
}
.img-article-4 {
  // background-image: url(../../images/article4.png);
  background-size: cover !important;
  width: 180px;
  height: 180px;
  border-radius: 16px;
  margin-right: 30px;

  @include xl {
    min-width: 140px;
    height: 140px;
    margin-right: 0px;
  }

  @include sm {
    max-width: 96px;
    max-height: 96px;
  }
}
.img-article {
  height: 450px;
  width: 100%;
  background-size: cover !important;
  border-radius: 24px;
  padding: 40px 15px;

  @include xl {
    height: 302px;
  }

  @include lg {
    height: 240px;
  }

  @include sm {
    height: 250px;
  }
}
.img-block2-1 {
  // background-image: url(../../images/article5.png);
}
.img-block2-2 {
  // background-image: url(../../images/article6.png);
}
.img-block2-3 {
  // background-image: url(../../images/article7.png);
}

.borderBottom {
  margin-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #d0d2d9;

  &:last-child {
    border-bottom: none;
  }
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.smoothAnimation {
  opacity: 0;
  animation: ani 2s forwards;
}

.preview-article {
  display: flex;
  // padding-bottom: 23px;
  margin-top: 40px;
  padding-bottom: 39px;
  border-bottom: 1px solid #d0d2d9;

  @include xl {
    padding-bottom: 30px;
    margin-top: 30px;
  }

  @include lg {
    .article-tag {
      padding: 6px 14px;
      font-size: 12px;
      font-weight: 600;
    }

    .publication-date {
      font-size: 12px;
      font-weight: 300;
    }
  }

  @include sm {
    justify-content: space-between;
    margin-top: 24px;
  }
}

.postSeparator {
  margin: 40px 0;
  width: 620px;
  height: 1px;
  background: #3d3e42;
  // padding: 0 12px;
}

.inner-article-preview {
  // max-width: 211px;

  @include xl {
    padding-left: 20px;

    .publication-date {
      padding-left: 0 !important;
    }
  }
}

.header-article-preview {
  max-width: 410px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #0f131b;

  @include xl {
    // padding-right: 20px !important;
    font-size: 18px;
  }

  @include sm {
    font-size: 14px;
  }
}
.small-text {
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: 10px;

  @include sm {
    font-size: 12px;
  }
}

// .collageMens-inner{
//     padding-bottom: 100px;
// }
.NBA-Draft {
  // width: 1440px;
  background-color: #f2f4f6;
  margin-left: -70px;
  padding: 0px 60px 60px 95px;

  @include xxl2 {
    width: 1430px;
  }
}

.nba-inner {
  display: flex;
  justify-content: space-between;
}
.nba-article-1 {
  background-color: #fff;
  width: 510px;
  height: 262px;
  border-radius: 0 0 24px 24px;
  overflow: hidden;
  padding: 20px 40px;
  // margin-right: 40px;

  @include xl {
    height: 268px;
    width: 382px;
  }

  @include lg {
    width: 275px;
    height: 212px;
    padding: 0 20px 20px 20px;
  }

  @include sm {
    width: 327px;
    height: 242px;
    margin-bottom: 24px;
  }
}
.nba-article-2 {
  background-color: #fff;
  width: 730px;
  height: 341px;
  border-radius: 24px;
  overflow: hidden;
  // margin-right: 40px;
  margin-bottom: 20px;
  margin-left: 8px;

  @include xxl2 {
    .inner-article-preview {
      padding-left: 0;
    }
  }

  @include xl {
    height: 279px;
    margin-left: 30px;
    width: 532px;
    margin-bottom: 30px;

    .inner-article-preview {
      padding-left: 20px;
      padding-right: 12px;
    }

    & .description-article {
      padding-left: 0 !important;
    }
  }

  @include lg {
    height: 221px;
    width: 393px;
    margin-bottom: 20px;

    .publication-date {
      font-size: 12px;
    }

    .header-article-preview {
      font-size: 14px;
    }
  }

  @include sm {
    width: 327px;
    height: 492px;
    margin-bottom: 24px;
    margin-left: 0;
  }
}
.img-article-8 {
  height: 441px;
  width: 510px;
  background-size: cover;
  padding: 40px 30px;
  border-radius: 24px 24px 0 0;
  // background-image: url(../../images/article8.png);

  @include xl {
    width: 382px;
    height: 320px;
  }

  @include lg {
    width: 275px;
    height: 250px;
  }

  @include sm {
    width: 327px;
    height: 250px;
  }
}
.img-article-9 {
  height: 340px;
  width: 330px;
  background-size: cover;
  padding: 40px 30px;
  border-radius: 24px;
  // background-image: url(../../images/article9.png);

  @include xl {
    width: 266px;
    height: 279px;
  }

  @include lg {
    width: 200px;
    height: 221px;
  }

  @include sm {
    height: 250px;
  }
}
.img-article-10 {
  height: 341px;
  width: 330px;
  background-size: cover;
  padding: 40px 30px;
  border-radius: 24px;
  // background-image: url(../../images/article10.png);

  @include xl {
    width: 266px;
    height: 279px;
  }

  @include lg {
    width: 200px;
    height: 221px;
  }

  @include sm {
    width: 327px;
    height: 250px;
  }
}
.position-tag {
  position: absolute;
  top: 30px;
  left: 30px;
}
// .nba-article-img{
//     position: relative;
// }
.img-article-11 {
  position: relative;
  height: 341px;
  width: 330px;
  background-size: cover;
  border-radius: 24px;
  // background-image: url(../../images/article11.png);

  // @include xl {
  //   width: 266px;
  //   height: 279px;
  // }

  // @include lg {
  //   width: 200px;
  //   height: 221px;
  // }

  // @include sm {
  //   width: 327px;
  //   height: 250px;
  // }
}
.img-article-12 {
  position: relative;
  height: 300px;
  width: 100%;
  background-size: cover;
  border-radius: 24px;
  // background-image: url(../../images/article12.png);

  @include xl {
    width: 100%;
    height: 279px;
  }

  @include lg {
    width: 100%;
    height: 221px;
  }

  @include sm {
    margin-top: 23px;
  }
}
.img-article-13 {
  position: relative;
  height: 300px;
  width: 100%;
  background-size: cover;
  border-radius: 24px;
  // background-image: url(../../images/article13.png);

  @include xl {
    width: 457px;
    height: 300px;
  }

  @include lg {
    width: 100%;
    height: 221px;
  }

  @include sm {
    margin-top: 23px;
  }
}

.img-article-14 {
  position: relative;
  height: 300px;
  width: 100%;
  background-size: cover;
  border-radius: 24px;
  // background-image: url(../../images/article12.png);

  @include xl {
    width: 457px;
    height: 300px;
  }

  @include lg {
    width: 100%;
    height: 221px;
  }

  @include sm {
    margin-top: 23px;
  }
}
.alaning-center {
  display: flex;
}
.articlePagination {
  display: flex;
  // font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  justify-content: flex-end;
  margin-bottom: 0;

  align-items: center;
  text-align: center;

  /* Text/grey */

  color: #6b6c72;

  & li {
    width: 36px;
    height: 36px;
    border: 1px solid #d0d2d9;
    border-radius: 6px;
    list-style: none;
    margin-left: 13px;

    // &.previous {
    //   display: none;
    // }

    &.activePage {
      color: #f26b0b;
      border: 1px solid #f26b0b;
    }

    & a {
      vertical-align: -webkit-baseline-middle;
      padding: 8px 10px;
    }
  }
}

.search-results__text {
  &:hover {
    cursor: pointer;
  }
}

.front-page-post-tag {
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 14px;
  padding: 7px 16px;
  border-radius: 100px;
  border: none;
  display: inline-flex;
}

.post-tag-coaching {
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 14px;
  padding: 7px 16px;
  border-radius: 100px;
  border: none;
  display: inline-flex;

  position: relative;
  margin-top: 24px;
  margin-bottom: 14px;
  // left: 20px;
  // top: 73%;

  &:not(:first-child) {
    margin-left: 8px;
    margin-bottom: 8px;
  }

  @include xl {
    font-size: 14px;
    padding: 8px 16px;
  }
}

.collegeMensContent {
  align-self: center;
}

.collegeWomensCard {
  @include xl {
    & .publication-date,
    & .description-article {
      padding-left: 0 !important;
    }

    & .article-tag {
      top: 30px !important;
    }
  }
}

.nbaDraft {
  padding-bottom: 100px;
  background-color: #f2f4f6;
  margin-left: -71px;
  margin-right: -71px;
  padding-left: 71px;
  padding-right: 71px;

  @include xl {
    margin-left: -43px;
    margin-right: -56px;
    padding-left: 43px;
    padding-right: 56px;
  }
}
.no-posts {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  text-align: center;

  /* Text/grey */

  color: #6b6c72;
}
.green-tag {
  color: #349b72;
  background-color: #e6fcf4;
}
.cyan-tag {
  color: #1e88ad;
  background-color: #d5f2fc;
}
.blue-tag {
  color: #4e7cc8;
  background-color: #dee9fc;
}
.red-tag {
  color: #c3577e;
  background-color: #fde1f2;
}
