// Media Query Variables
$xs: 374px;
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1440px;
$xxl2: 1440px;

@mixin xs {
  @media (max-width: #{$xs}) {
    @content;
  }
}
//  small devices
@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (max-width: #{$md}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}
// Extra large devices
@mixin xl {
  @media (max-width: #{$xl}) {
    @content;
  }
}

//Width: 1440px
@mixin xxl2 {
  @media (max-width: #{$xxl2}) {
    @content;
  }
}

.title-block {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0 60px 0;

  @include xl {
    padding-top: 120px;
  }
}
.title {
  font-family: var(--font-oswald);
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 150%;

  @include xl {
    font-size: 42px;
  }

  @include sm {
    font-size: 28px !important;
  }
}
.orange-line {
  background-image: url("../../assets/images/orange-line.png");
  padding-bottom: 23px;
  width: 100%;
  background-color: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 12px;
  padding-bottom: 7px;
  padding-right: 6px;
}

.tweet-block {
  position: relative;
  // width: 400px;
  height: 303px;
  background: #ffffff;
  border-radius: 24px;
  padding: 30px;
  margin-bottom: 160px;

  @include sm {
    margin-bottom: 24px;
  }
}

.recentTweets {
  .tweet-block &:last-child {
    color: red !important;
  }
}
.autor-info {
  display: flex;
}
.avatar-1 {
  background-image: url("../../assets/images/avatar1.jpeg");
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 12px;
}
.avatar-11 {
  background-image: url("https://pbs.twimg.com/profile_images/1502469232226971655/JJMuAY4N_400x400.jpg");
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 12px;
}
.avatar-2 {
  background-image: url("../../assets/images/avatar2.jpeg");
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 12px;
}
.avatar-22 {
  background-image: url("../../assets/images/profiles/MikeMcClure.png");
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 12px;
}
.avatar-3 {
  background-image: url("../../assets/images/avatar3.jpeg");
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 12px;
}
.autor-text {
  font-family: var(--font-oswald);
  margin: 10px 20px;
}
.name-autor {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
}
.nickname {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #6b6c72;
}
.tweeter-logo {
  position: absolute;
  // left: 340px;
  right: 25px;
  top: 25px;
}
.tweet-text {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #0f131b;
  padding-top: 15px;
}
.btn-block {
  display: flex;
  justify-content: center;
  padding-bottom: 160px;
}
.btn-sm {
  background: transparent;
  font-family: var(--font-inter);
  color: #0f131b;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  width: 163px;
  height: 56px;
  border: 1px solid #0f131b;
  border-radius: 12px;
}
